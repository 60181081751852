/* Arrows */



.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  font-size: 0;
  background: $yellow;
  color: $black;
  top: 45%;
  margin-top: -10px\9; /*lte IE 8*/
  transform: translate(0, -45%);
  padding: .8rem;
  border: none;
  outline: none;
  z-index: 10;
  transition:
  background-color $transition,
  color $transition;
  &.slick-disabled:before {
    opacity: 1;
  }
  &:hover {
    background: $blue;
    color: $white;
  }
}

.slick-prev {
  left: 0;
  .svgstore--arrow {
    margin-left: 0.8rem;
    width: 2rem;
    transform: rotate(90deg);
  }
}

.slick-next {
  right: 0;

  .svgstore--arrow {
    margin-left: 0.8rem;
    width: 2rem;
    transform: rotate(-90deg);
  }
}


/* Slider */
.slick-slide {outline: none;}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  // transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}

