.align-left,
.align-right {
  margin-bottom: 1.25rem;

  @include mq(48em) {
    max-width: 33.3333%;
  }
}

.align-left {
  @include mq(48em) {
    margin-right: 1.25rem;
    float: left;
  }
}

.align-right {
  @include mq(48em) {
    margin-left: 1.25rem;
    float: right;
  }
}

.align-small {
  @include mq(48em) {
    max-width: 25%;
  }
}

.align-zero {
  @include mq(48em) {
    margin-bottom: 0;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.padding {
  padding: 2rem;
}

// .green {
//   background-color: $green;
// }

.orange-alt {
  background-color: $orange-alt;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $black-opacity;
  pointer-events: none;
}

// Clear margin and padding
@mixin clean {
  margin: 0;
  padding: 0;
}

// hide
@mixin hide {
  position: absolute;
  left: -999rem;
}

@mixin sr-hide {
  position: absolute;
  left: -999rem;
  visibility: hidden;
}

.hide {
  @include hide;
}

// show
@mixin show {
  position: static;
  left: auto;
}

@mixin sr-show {
  position: static;
  left: auto;
  visibility: visible;
}

.skip a {
  position: absolute;
  left: -10000rem;
  top: -2rem;
  width: .1rem;
  height: .1rem;
  overflow: hidden;
  z-index: 100;
  color: #fff;
  outline: 0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-bottom-right-radius: .3rem;
  transition: top 1s ease-out;
  &:focus {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    background: red;
    transition: top .1s ease-in;
  }
}

.mobile--hidden {
  position: absolute;
  left: -999rem;
  @include mq(64em) {
    position: static;
    left: 0;
  }
}

.break {
  &:after {
    content: "\000A";
    white-space: pre;
    @include mq(64em) {
      content: "\000A";
    }
  }
}

// Cookie content styles
.cc-window,
.cc-btn {

  font-family: $sans-serif !important;
}
.cc-window.cc-banner {
  line-height: 1.25 !important;
  padding: 1em !important;
}

.cc-compliance {
  display: block;
}

.cc-btn {
  width: 100%;
  @include mq(48em) {
    width: auto;
  }
}

.cc-link {
  padding-left: 0 !important;
}
