.event {
  @include clear;
  margin-top: 2.5rem;
  margin-bottom: 0;

  @include mq(48em) {
    margin-bottom: 2.5rem;
  }
  .button__solid {
    margin-top: 2.75rem;
  }
}

.event--vertical {

}

.event__top-border {
  border-top: 5px solid $blue;
}

.event__img {
  margin-bottom: 1rem;
  @include mq(32em) {

    float: left;
    margin-right: 1rem;
    .event--vertical & {
      float: none;
    }
  }
}

.event__block {
  width: 100%;
  float: left;
  @include mq(32em){
    width: 10rem;
  }
}

.event__block--date {
  @include font-size(20);
  font-weight: $bold;
}



.event__content--title {
  @extend .p--small;
  font-weight: $bold;
}

.event__content--link {
  @extend .h4;
  @include fluid-type(font-size, $minScreen, $maxScreen, 18px, 24px);
  margin-top: 0;
  .event--vertical & {
    @extend .h5;
    margin-top: 0.5rem;
  }
}

.event__content {
  overflow: hidden;
  width: 100%;
  @include mq(32em){
    width: auto;
  }
}

.event__item {
  @include clear;
  border-bottom: 1px solid $green-dark;
  padding: 2rem 0 1rem;
  // &:first-child {
  //   border-top: 0.3rem solid $blue-dark;
  // }
}

.event__divider {
  border-top: 5px solid $blue;
  margin: 0.75rem 0 1rem;
}