@media print {
  header, nav, .footer,
  .alert, .juicer, .sidebar,
  .calendar {
    display: none;
  }

  .ungrid {
    .grid {
      display: block !important;
    }
    .grid__item {
      float: none !important;
    }
  }

  .container--purple-dark {
    color: $black !important;
  }

  * {
    margin-bottom: 0;
  }

  .main {
    margin: 0 !important;
  }

  .page {
    padding-top: 0 !important;
    overflow: hidden;
  }

  p a,
  .feed__item a,
  .image__item a,
  .event__content--link a {
    word-wrap: break-word;
    color: $black;
    &:after {
      // content: " (" attr(href) ")";
      // font-size: 80%;
    }
  }

  .tabs__nav__link:after,
  .accordion__toggle:after {
    content: ''
  }



  .tabs__nav__item {
    display: none !important;
  }

  .tabs__main__content {
    position: static !important;
    visibility: visible !important;
  }

  .tabs__main__toggle {
    display: block !important;
  }

  .tabs__main__interior {
    padding: 1rem 0 0 !important;
    border-top: 0 !important;
  }

  // .tabs__main__item {
  //   border-bottom: 0.1rem solid $purple-dark !important;
  // }


  .accordion__interior {
    border-top: 0 !important;
  }
  .accordion__content {
    position: static !important;

  }
  .accordion__interior {
    padding: 1rem 0 0 !important;
  }

  .block__img:before {
    background: transparent;
  }


  .block__text {
    text-shadow: none;
    color: $black;
  }

  .block__heading {
    color: $black;
    text-shadow: none;
  }


  .button__link:after,
  .subnav__link--horizontal:after {
    // content: " (" attr(href) ")" !important;
    position: static !important;
    background-color: transparent !important;
  }

  .button__overlay {
    color: $black;
    text-shadow: none;
  }

  .media__icon, .play__icon, .circle__arrow,
  .alert__close, .story__hero__arrow, .hero__arrow {
    display: none;
  }

  .appear-bottom {
    opacity: 1;
  }

  .animateInHome {
    transform: translateX(0);
  }

  .main {
    width: 100% !important;
  }

  .slick-arrow,
  .figure__slider,
  .figure__slider--alt,
  .slick-slide img,
  img {
    display: none !important;
  }

  .responsive-table {
    thead {
      padding: 0 !important;
      border: 0 !important;
      position: relative !important;
      clip: auto !important;
      height: auto !important;
      width: auto !important;
      overflow: auto !important;
    }

    tr {
      &:nth-of-type(odd) {
        background: $gray-lighter !important;
        display: table-row !important;
      }
    }

    th,
    td {
      padding: .5em;
      vertical-align: middle;
      display: table-cell !important;
    }

    tbody {
      display: table-row-group !important;

      tr {
        display: table-row !important;
        border-width: 1px !important;

      }

      th[scope="row"] {
        background-color: transparent !important;
        color: $black !important;
        text-align: left !important;
      }

      td {
        text-align: center !important;
        &:nth-of-type(odd) {
          background-color: transparent !important;
        }
      }


      td[data-title]:before {
        content: none !important;
      }
    }
  }


}
