.widget {
  @include clear;
  @include spacing(2);
}

.widget--border {
  padding-top: 4rem;
  padding-bottom: 4rem;
  border-top: 1px solid $green-dark;
  border-bottom: 1px solid $green-dark;
}

.widget__img {
  margin-bottom: 1rem;
  @include mq(48em) {
    float: left;
    margin-right: 2rem;
    width: 45%;
    margin-bottom: 0;
  }
}

.widget__content {
  overflow: hidden;
  .button__solid {
    white-space: inherit;
  }
}

.widget__content--title {
  margin-top: 2rem;
  @include mq(48em) {
    margin-top: 0;
  }
}
