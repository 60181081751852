.alert {
  width: 100%;
  background: $red;
  color: $white;
  z-index: 12;
  padding: 1.25rem 0;
  a {
    color: $white;
  }
}



.alert__container {
  position: relative;
  padding: 0 0.5rem;
  @include mq(64em) {
    padding: 0 2rem;
  }
}

.alert__text {
  padding: 0 4rem 0;
}

.alert__label {
  @include font-size(16);
  @include mq(64em) {
    @include font-size(21);
  }
}

.alert__icon,
.alert__close {
  position: absolute;
  top: 1rem;
  @include mq(64em) {

    top: 50%;
    transform: translateY(-50%);
  }

}

.alert__icon {
  width: 2.5rem;
}

.alert__close {
  right: 0.5rem;
  width: 1.25rem;
  @include mq(64em) {
    right: 2.5rem;
  }
}

.alert__button {
  padding-top: 1.5rem;
  display: block;
  @include mq(64em) {
    padding-top: 0;
    float: right;
  }
}
