.juicer {
  //background-color: $gray-lighter;
  padding: 2rem 1rem;
  .j-poster img {
    display: inline-block;
  }
}

.juicer-feed .j-paginate {
  transition: all 0.2s ease-in-out;
  display: inline-block;
  border: 0 !important;
  font-size: 17px !important;
  font-weight: bold;
  letter-spacing: 0.5px !important;
  color: $black !important;
  cursor: pointer;
  background-color: $yellow !important;
  width: 125px !important;
  text-transform: none !important;
  padding: 0.5rem 1rem !important;
  &:hover {
    color: $white !important;
    background-color: $blue !important;
  }
}

.j-meta {
  a {
    color: $gray !important;
    &:hover {
      color: $blue !important;
    }
  }
}

.j-poster-meta {
  margin-top: 0 !important;
}
