@charset "UTF-8";
/*! sanitize.css v4.1.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Display definitions
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 * 2. Add the correct display in IE.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  /* 1 */
  display: block; }

/**
 * Add the correct display in IE 9-.
 */
audio,
canvas,
progress,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Add the correct display in IE 10-.
 * 1. Add the correct display in IE.
 */
template,
[hidden] {
  display: none; }

/* Elements of HTML (https://www.w3.org/TR/html5/semantics.html)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Add a flattened line height in all browsers (opinionated).
 * 4. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  font-family: sans-serif;
  /* 3 */
  line-height: 1.5;
  /* 3 */
  -ms-text-size-adjust: 100%;
  /* 4 */
  -webkit-text-size-adjust: 100%;
  /* 5 */ }

/* Sections (https://www.w3.org/TR/html5/sections.html)
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font sizes and margins on `h1` elements within
 * `section` and `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: .67em 0; }

/* Grouping content (https://www.w3.org/TR/html5/grouping-content.html)
   ========================================================================== */
/**
 * 1. Correct font sizing inheritance and scaling in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * 1. Correct the height in Firefox.
 * 2. Add visible overflow in Edge and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Add a bordered underline effect in all browsers.
 * 2. Remove text decoration in Firefox 40+.
 */
abbr[title] {
  border-bottom: 1px dotted;
  /* 1 */
  text-decoration: none;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Add the correct colors in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000; }

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline; }

/**
 * Correct the font size in all browsers.
 */
small {
  font-size: 83.3333%; }

/**
 * Change the positioning on superscript and subscript elements
 * in all browsers (opinionated).
 * 1. Correct the font size in all browsers.
 */
sub,
sup {
  font-size: 83.3333%;
  /* 1 */
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

/* Embedded content (https://www.w3.org/TR/html5/embedded-content-0.html)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Links (https://www.w3.org/TR/html5/links.html#links)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove the gaps in underlines in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * Remove the outline when hovering in all browsers (opinionated).
 */
a:hover {
  outline-width: 0; }

/* Tabular data (https://www.w3.org/TR/html5/tabular-data.html)
   ========================================================================== */
/*
 * Remove border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* transform-style:  (https://www.w3.org/TR/html5/forms.html)
   ========================================================================== */
/**
 * 1. Remove the default styling in all browsers (opinionated).
 * 3. Remove the margin in Firefox and Safari.
 */
button,
input,
select,
textarea {
  background-color: transparent;
  /* 1 */
  border-style: none;
  /* 1 */
  color: inherit;
  /* 1 */
  font-size: 1em;
  /* 1 */
  margin: 0;
  /* 3 */ }

/**
 * Correct the overflow in IE.
 * 1. Correct the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance in Edge, Firefox, and IE.
 * 1. Remove the inheritance in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent the WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Correct the focus styles unset by the previous rule.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * Correct the border, margin, and padding in all browsers.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: .35em .625em .75em; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Remove the vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */ }

/**
 * Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  padding: 0; }

/**
 * Correct the cursor style on increment and decrement buttons in Chrome.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari for OS X.
 */
::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Correct the text style on placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: .54; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/* WAI-ARIA (https://www.w3.org/TR/html5/dom.html#wai-aria)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default; }

/* User interaction (https://www.w3.org/TR/html5/editing.html)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation; }

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[hidden][aria-hidden="false"] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

[hidden][aria-hidden="false"]:focus {
  clip: auto; }

select {
  -moz-appearance: none;
  /* Firefox 40+ */
  -webkit-appearance: none; }

select::-ms-expand {
  display: none;
  /* Edge 12+, Internet Explorer 11- */ }

select::-ms-value {
  color: currentColor;
  /* Edge 12+, Internet Explorer 11- */ }

@keyframes fadeUp {
  0% {
    transform: translateY(70%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes topIn {
  0% {
    top: -100%; }
  100% {
    top: 0; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.lazyload,
.lazyloading {
  opacity: 0; }

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms; }

.appear-bottom {
  opacity: 0;
  transform: translateY(80px) translateZ(0);
  transition: transform 700ms cubic-bezier(0.165, 0.84, 0.44, 1), opacity 700ms cubic-bezier(0.165, 0.84, 0.44, 1); }

.appear-bottom.is-active {
  opacity: 1;
  transform: translateZ(0); }

.wf-loading * {
  opacity: 0; }

* {
  box-sizing: border-box; }

:root {
  overflow-x: hidden;
  word-wrap: break-word; }

button {
  cursor: pointer; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

audio::-internal-media-controls-download-button {
  display: none; }

audio::-webkit-media-controls-enclosure {
  overflow: hidden; }

audio::-webkit-media-controls-panel {
  width: calc(100% + 30px);
  /* Adjust as needed */ }

figure {
  margin: 0; }

strong {
  font-weight: 700; }

.svgstore {
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  pointer-events: none; }
  .svgstore svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.svgstore--ArrowLeft {
  padding-bottom: 95.2381%; }

.svgstore--ArrowRight {
  padding-bottom: 100%; }

.svgstore--ArrowUp {
  padding-bottom: 94.44444%; }

.svgstore--VolDown {
  padding-bottom: 175%; }

.svgstore--VolUp {
  padding-bottom: 125%; }

.svgstore--alert {
  padding-bottom: 100%; }

.svgstore--arrow_down {
  padding-bottom: 54.34783%; }

.svgstore--arrow_left {
  padding-bottom: 184%; }

.svgstore--arrow_right {
  padding-bottom: 184%; }

.svgstore--arrow_up {
  padding-bottom: 54.59459%; }

.svgstore--audio-play {
  padding-bottom: 100%; }

.svgstore--beaker {
  padding-bottom: 58.10811%; }

.svgstore--cal {
  padding-bottom: 100%; }

.svgstore--check_box {
  padding-bottom: 100%; }

.svgstore--check_box_outline {
  padding-bottom: 100%; }

.svgstore--close {
  padding-bottom: 100%; }

.svgstore--comment {
  padding-bottom: 100%; }

.svgstore--computer {
  padding-bottom: 58.10811%; }

.svgstore--diploma {
  padding-bottom: 58.10811%; }

.svgstore--dropdownToggle {
  padding-bottom: 50%; }

.svgstore--email {
  padding-bottom: 100%; }

.svgstore--facebook {
  padding-bottom: 100%; }

.svgstore--fax {
  padding-bottom: 100%; }

.svgstore--gallery {
  padding-bottom: 94.11765%; }

.svgstore--google-plus {
  padding-bottom: 100%; }

.svgstore--grid_view {
  padding-bottom: 80%; }

.svgstore--heart {
  padding-bottom: 100%; }

.svgstore--hero_arrow {
  padding-bottom: 285.71429%; }

.svgstore--home_arrow {
  padding-bottom: 707.14286%; }

.svgstore--house {
  padding-bottom: 57.60369%; }

.svgstore--indeterminate_check_box {
  padding-bottom: 100%; }

.svgstore--instagram {
  padding-bottom: 100%; }

.svgstore--leaf {
  padding-bottom: 58.10811%; }

.svgstore--linkedin {
  padding-bottom: 100%; }

.svgstore--list_view {
  padding-bottom: 64%; }

.svgstore--logo {
  padding-bottom: 29.06977%; }

.svgstore--menu {
  padding-bottom: 76%; }

.svgstore--mountain-alt {
  padding-bottom: 57.60369%; }

.svgstore--mountain {
  padding-bottom: 57.60369%; }

.svgstore--news {
  padding-bottom: 100%; }

.svgstore--pause-sound {
  padding-bottom: 100%; }

.svgstore--person {
  padding-bottom: 58.10811%; }

.svgstore--phone {
  padding-bottom: 100%; }

.svgstore--play-sound {
  padding-bottom: 100%; }

.svgstore--play_video {
  padding-bottom: 100%; }

.svgstore--pre-footer-logo-business {
  padding-bottom: 10.82569%; }

.svgstore--pre-footer-logo-education {
  padding-bottom: 12.36674%; }

.svgstore--quote {
  padding-bottom: 80%; }

.svgstore--radial_button_checked {
  padding-bottom: 100%; }

.svgstore--radial_button_unchecked {
  padding-bottom: 100%; }

.svgstore--search {
  padding-bottom: 100%; }

.svgstore--share {
  padding-bottom: 80%; }

.svgstore--success {
  padding-bottom: 100%; }

.svgstore--trans {
  padding-bottom: 58.10811%; }

.svgstore--twitter {
  padding-bottom: 91.66667%; }

.svgstore--video-play {
  padding-bottom: 100%; }

.svgstore--youtube {
  padding-bottom: 68.29268%; }

body {
  font-family: "Raleway", sans-serif;
  font-size: 1.125rem;
  line-height: 1.56;
  color: #010101; }

h1, .h1,
h2, .h2,
h3, .h3, .faculty__content--name,
h4, .h4, .event__content--link,
h5, .h5, .event--vertical .event__content--link, .faculty__slide .faculty__content--name, .feed__heading,
h6, .h6, .form__label {
  margin: 0 0 1rem 0; }

h1,
.h1 {
  font-weight: 300;
  font-size: 2.2rem;
  color: #651D32;
  line-height: 1.148; }
  @media (min-width: 32em) {
    h1,
    .h1 {
      font-size: 2.875rem; } }
  @media (min-width: 48em) {
    h1,
    .h1 {
      font-size: 3.125rem; } }

h2,
.h2 {
  font-weight: 300;
  font-size: 1.875rem;
  color: #651D32;
  line-height: 1.26;
  margin-bottom: 0.5rem; }
  @media (min-width: 32em) {
    h2,
    .h2 {
      font-size: 2.375rem; } }

h3,
.h3,
.faculty__content--name {
  font-size: 2rem;
  line-height: 1.18;
  font-weight: 400;
  margin-top: 3rem;
  margin-bottom: 0.5rem; }

h4,
.h4,
.event__content--link {
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 2rem;
  color: #010101; }
  .tabs h4,
  .accordion h4,
  .tabs
  .h4,
  .tabs .event__content--link,
  .accordion
  .h4,
  .accordion .event__content--link {
    margin-top: 0; }

.h4, .event__content--link, h4 + .event__divider {
  margin-bottom: 1rem; }

h5,
.h5,
.event--vertical .event__content--link,
.faculty__slide .faculty__content--name,
.feed__heading {
  font-size: 1.3125rem;
  font-weight: 400;
  line-height: 1.33;
  margin-top: 3rem;
  margin-bottom: 0.5rem; }

h6,
.h6,
.form__label {
  font-size: 1.0625rem;
  font-weight: 700;
  line-height: 1.29;
  margin-top: 3rem;
  margin-bottom: 0.5rem; }

.clear-heading {
  margin-top: 0; }

a:not([class]),
a[class=""],
a[class="ally-focus-within"] {
  color: #9D56CC;
  text-decoration: none; }
  a:not([class]):hover, a:not([class]):focus,
  a[class=""]:hover,
  a[class=""]:focus,
  a[class="ally-focus-within"]:hover,
  a[class="ally-focus-within"]:focus {
    text-decoration: underline; }

.anchor {
  color: #9D56CC;
  text-decoration: none; }
  .anchor:hover {
    text-decoration: underline; }

ul,
ol {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding-left: 1.25rem; }

li {
  margin-bottom: 0.75rem; }

p {
  margin: 0 0 1.111rem 0; }

.p--small, .event__content--title, .faculty__content--title, .image__caption {
  font-size: 0.9375rem;
  line-height: 1.46; }

.p--intro, .p--intro-program {
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.41; }

.p--intro-program {
  color: #236192; }

.ul--plain, .button__ghost__group, .button__group, .button__group--alt, .button__list {
  margin: 0;
  padding: 0;
  list-style: none; }

.ul--plain-no-space {
  margin: 0;
  padding: 0;
  list-style: none; }
  .ul--plain-no-space li {
    margin-bottom: 0; }

.ul--inline li {
  display: inline-block;
  margin: 0.25rem 1rem; }

hr {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 0;
  height: 1px;
  background-color: #012169; }

.hr--gray {
  background-color: #53565A; }

.hr--green-dark {
  background-color: #A0D1CA; }

.hr--green-dark-alt {
  background-color: #A0D1CA; }
  @media (max-width: 48em) {
    .hr--green-dark-alt {
      margin-top: -2.5rem;
      margin-bottom: -1.5rem; } }

.hr--purple {
  background-color: #9D56CC; }

.hr--purple {
  background-color: #702F8A; }

.tag {
  color: #53565A;
  font-size: 0.9375rem;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 2.34;
  margin-left: 5px; }

.fadeIn {
  animation-name: fadeIn; }

.animate {
  visibility: hidden; }

.animated {
  visibility: visible !important;
  animation-duration: 1s;
  animation-fill-mode: both; }

@media (min-width: 48em) {
  .animate-text {
    visibility: hidden; } }

@media (min-width: 48em) {
  .animated-text {
    visibility: visible !important;
    animation-duration: 1s;
    animation-fill-mode: both; } }

.page {
  padding-top: 3.1rem;
  overflow-x: hidden; }
  @media (min-width: 64em) {
    .page {
      padding-top: 6.25rem; } }
  @media (max-width: 64em) {
    .search-active .page,
    .menu-active .page {
      overflow-y: hidden;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; } }

.wrap, .faculty__slider,
.faculty__slider--alt, .figure__slider, .footer__bottom__wrap, .hero__wrap {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem; }
  .wrap::before, .faculty__slider::before,
  .faculty__slider--alt::before, .figure__slider::before, .footer__bottom__wrap::before, .hero__wrap::before, .wrap::after, .faculty__slider::after,
  .faculty__slider--alt::after, .figure__slider::after, .footer__bottom__wrap::after, .hero__wrap::after {
    content: '';
    display: table; }
  .wrap::after, .faculty__slider::after,
  .faculty__slider--alt::after, .figure__slider::after, .footer__bottom__wrap::after, .hero__wrap::after {
    clear: both; }
  @media (min-width: 48em) {
    .wrap, .faculty__slider,
    .faculty__slider--alt, .figure__slider, .footer__bottom__wrap, .hero__wrap {
      padding-right: 2rem;
      padding-left: 2rem; } }
  @media (min-width: 90em) {
    .wrap, .faculty__slider,
    .faculty__slider--alt, .figure__slider, .footer__bottom__wrap, .hero__wrap {
      padding-right: 3rem;
      padding-left: 3rem; } }

@media (max-width: 30em) {
  .wrap > .wrap, .faculty__slider > .wrap,
  .faculty__slider--alt > .wrap, .figure__slider > .wrap, .footer__bottom__wrap > .wrap, .hero__wrap > .wrap, .wrap > .faculty__slider, .faculty__slider > .faculty__slider,
  .faculty__slider--alt > .faculty__slider, .figure__slider > .faculty__slider, .footer__bottom__wrap > .faculty__slider, .hero__wrap > .faculty__slider, .wrap >
  .faculty__slider--alt, .faculty__slider >
  .faculty__slider--alt,
  .faculty__slider--alt >
  .faculty__slider--alt, .figure__slider >
  .faculty__slider--alt, .footer__bottom__wrap >
  .faculty__slider--alt, .hero__wrap >
  .faculty__slider--alt, .wrap > .figure__slider, .faculty__slider > .figure__slider,
  .faculty__slider--alt > .figure__slider, .figure__slider > .figure__slider, .footer__bottom__wrap > .figure__slider, .hero__wrap > .figure__slider, .wrap > .footer__bottom__wrap, .faculty__slider > .footer__bottom__wrap,
  .faculty__slider--alt > .footer__bottom__wrap, .figure__slider > .footer__bottom__wrap, .footer__bottom__wrap > .footer__bottom__wrap, .hero__wrap > .footer__bottom__wrap, .wrap > .hero__wrap, .faculty__slider > .hero__wrap,
  .faculty__slider--alt > .hero__wrap, .figure__slider > .hero__wrap, .footer__bottom__wrap > .hero__wrap, .hero__wrap > .hero__wrap {
    padding-left: 0;
    padding-right: 0; } }

.wrap--alt {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem; }
  .wrap--alt::before, .wrap--alt::after {
    content: '';
    display: table; }
  .wrap--alt::after {
    clear: both; }

.wrap--wide {
  max-width: 96rem; }

.wrap--extra-wide {
  max-width: 96rem; }
  @media (min-width: 48em) {
    .wrap--extra-wide {
      padding-left: 0;
      padding-right: 0; } }

.wrap--narrow {
  max-width: 70rem; }

.wrap--slim {
  max-width: 58rem;
  margin: 0 auto; }

.wrap--relative {
  position: relative; }

@media (max-width: 30em) {
  .wrap--mobile-padding {
    margin-right: auto;
    margin-left: auto;
    padding-right: 1rem;
    padding-left: 1rem; } }

.main,
.sidebar,
.main--full {
  margin-top: 5rem;
  margin-bottom: 5rem; }
  @media (min-width: 64em) {
    .main,
    .sidebar,
    .main--full {
      margin-top: 5rem;
      margin-bottom: 5rem; } }

.main--full + div {
  margin-top: -5rem; }

.main--breadcrumb {
  margin-top: 4rem;
  margin-bottom: -3rem; }
  @media (min-width: 64em) {
    .main--breadcrumb {
      margin-bottom: -5rem;
      width: calc(100% - 22rem);
      float: right; } }
  @media (min-width: 90em) {
    .main--breadcrumb {
      width: calc(100% - 24rem); } }

.main {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  @media (min-width: 64em) {
    .main {
      margin-top: 5rem;
      margin-bottom: 5rem;
      width: calc(100% - 22rem);
      float: right; } }
  @media (min-width: 90em) {
    .main {
      width: calc(100% - 24rem); } }

@media (min-width: 64em) {
  .main--center {
    margin-right: auto;
    margin-left: auto;
    float: none; } }

@media (min-width: 64em) {
  .sidebar {
    width: 18rem;
    float: left;
    clear: left; } }

@media (min-width: 64em) {
  .main + .sidebar + .main {
    margin-top: 0; } }

.sidebar + .main + .sidebar {
  margin: 0; }
  @media (min-width: 64em) {
    .sidebar + .main + .sidebar {
      margin-top: -2.5rem; } }

.section {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.section--4 {
  margin-top: 5rem;
  margin-bottom: 5rem; }

.section--4--mobile {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  @media (min-width: 48em) {
    .section--4--mobile {
      margin-top: 5rem;
      margin-bottom: 5rem; } }

.section--5 {
  margin-top: 6.25rem;
  margin-bottom: 6.25rem; }

.section-padding--4 {
  padding: 4.25rem 0; }

.section-padding__top--4 {
  padding-top: 4.25rem; }

.section__top--4 {
  margin-top: 4.25rem; }

.section__bottom--4 {
  margin-bottom: 4.25rem; }

.container--gray {
  background-color: #53565A; }

.container--gray-lighter {
  background-color: #EFEFEF; }

.container--red {
  background-color: red; }

.container--purple-dark {
  background-color: #702F8A;
  color: #ffffff; }

.container--maroon {
  background-color: #651D32;
  color: #ffffff; }

.align-left,
.align-right {
  margin-bottom: 1.25rem; }
  @media (min-width: 48em) {
    .align-left,
    .align-right {
      max-width: 33.3333%; } }

@media (min-width: 48em) {
  .align-left {
    margin-right: 1.25rem;
    float: left; } }

@media (min-width: 48em) {
  .align-right {
    margin-left: 1.25rem;
    float: right; } }

@media (min-width: 48em) {
  .align-small {
    max-width: 25%; } }

@media (min-width: 48em) {
  .align-zero {
    margin-bottom: 0; } }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.padding {
  padding: 2rem; }

.orange-alt {
  background-color: #FFCD86; }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: none; }

.hide {
  position: absolute;
  left: -999rem; }

.skip a {
  position: absolute;
  left: -10000rem;
  top: -2rem;
  width: .1rem;
  height: .1rem;
  overflow: hidden;
  z-index: 100;
  color: #fff;
  outline: 0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-bottom-right-radius: .3rem;
  transition: top 1s ease-out; }
  .skip a:focus {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    background: red;
    transition: top .1s ease-in; }

.mobile--hidden {
  position: absolute;
  left: -999rem; }
  @media (min-width: 64em) {
    .mobile--hidden {
      position: static;
      left: 0; } }

.break:after {
  content: "\000A";
  white-space: pre; }
  @media (min-width: 64em) {
    .break:after {
      content: "\000A"; } }

.cc-window,
.cc-btn {
  font-family: "Raleway", sans-serif !important; }

.cc-window.cc-banner {
  line-height: 1.25 !important;
  padding: 1em !important; }

.cc-compliance {
  display: block; }

.cc-btn {
  width: 100%; }
  @media (min-width: 48em) {
    .cc-btn {
      width: auto; } }

.cc-link {
  padding-left: 0 !important; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  font-size: 0;
  background: #E1D555;
  color: #010101;
  top: 45%;
  margin-top: -10px\9;
  /*lte IE 8*/
  transform: translate(0, -45%);
  padding: .8rem;
  border: none;
  outline: none;
  z-index: 10;
  transition: background-color 300ms ease, color 300ms ease; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 1; }
  .slick-prev:hover,
  .slick-next:hover {
    background: #236192;
    color: #ffffff; }

.slick-prev {
  left: 0; }
  .slick-prev .svgstore--arrow {
    margin-left: 0.8rem;
    width: 2rem;
    transform: rotate(90deg); }

.slick-next {
  right: 0; }
  .slick-next .svgstore--arrow {
    margin-left: 0.8rem;
    width: 2rem;
    transform: rotate(-90deg); }

/* Slider */
.slick-slide {
  outline: none; }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.accordion {
  margin-bottom: 2rem; }
  .accordion::before, .accordion::after {
    content: '';
    display: table; }
  .accordion::after {
    clear: both; }

.accordion__item, .tabs__main__item {
  margin-bottom: 0.125rem;
  border-bottom: 0.1rem solid #702F8A; }

.accordion__item--active {
  border-bottom: 0; }
  .accordion__item--active .accordion__interior {
    border-bottom: 0.1rem solid #702F8A;
    border-top: none; }

.accordion__toggle, .tabs__main__toggle {
  display: block;
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: #702F8A;
  text-decoration: none; }

.accordion__icon, .tabs__main__icon {
  width: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  display: inline-block;
  float: left; }

.accordion__text {
  display: block;
  overflow: hidden; }

.accordion__icon__open {
  display: block; }
  .accordion__item--active .accordion__icon__open {
    transform: rotate(90deg); }

.accordion__content {
  position: absolute;
  left: -999rem; }
  .accordion__item--active .accordion__content {
    position: static; }

.accordion__interior {
  padding: 1.5rem 0 1.5rem 2.25rem;
  border-top: 0.1rem solid #702F8A; }

.alert {
  width: 100%;
  background: #DA291C;
  color: #ffffff;
  z-index: 12;
  padding: 1.25rem 0; }
  .alert a {
    color: #ffffff; }

.alert__container {
  position: relative;
  padding: 0 0.5rem; }
  @media (min-width: 64em) {
    .alert__container {
      padding: 0 2rem; } }

.alert__text {
  padding: 0 4rem 0; }

.alert__label {
  font-size: 1rem; }
  @media (min-width: 64em) {
    .alert__label {
      font-size: 1.3125rem; } }

.alert__icon,
.alert__close {
  position: absolute;
  top: 1rem; }
  @media (min-width: 64em) {
    .alert__icon,
    .alert__close {
      top: 50%;
      transform: translateY(-50%); } }

.alert__icon {
  width: 2.5rem; }

.alert__close {
  right: 0.5rem;
  width: 1.25rem; }
  @media (min-width: 64em) {
    .alert__close {
      right: 2.5rem; } }

.alert__button {
  padding-top: 1.5rem;
  display: block; }
  @media (min-width: 64em) {
    .alert__button {
      padding-top: 0;
      float: right; } }

.player {
  position: relative;
  padding-top: 5.5rem;
  margin-bottom: 2rem;
  color: #010101; }

.btn__play {
  display: block;
  float: left;
  position: relative;
  cursor: pointer;
  color: #702F8A;
  padding: .25rem; }
  @media (min-width: 64em) {
    .btn__play {
      padding: 0 1.25rem; }
      .iOS .btn__play {
        padding: 0; } }

.btn__play--white {
  color: #ffffff; }

.btn__play__arrow,
.btn__play__pause {
  display: block;
  width: 3rem;
  color: #702F8A;
  border: 0;
  position: relative; }
  .iOS .btn__play__arrow, .iOS
  .btn__play__pause {
    width: 60px; }
  .btn__play__arrow:hover, .btn__play__arrow:focus, .btn__play__arrow:active,
  .btn__play__pause:hover,
  .btn__play__pause:focus,
  .btn__play__pause:active {
    color: #9D56CC; }

.player__footer {
  background: #EFEFEF;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 0.9375rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0.5rem; }
  @media (min-width: 30em) {
    .player__footer {
      padding: 1.5rem 0 1.5rem 1rem; } }

.player__controls {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  padding-right: 1rem; }

.player__timeline, .player__volume__bar {
  height: 0.5rem;
  position: relative;
  background: #ffffff;
  display: inline-block;
  cursor: pointer; }

.player__volume__bar {
  background: #d8d6d6; }

.player__timeline {
  margin-left: 10px;
  margin-right: 15px;
  border-radius: .3rem;
  width: 70%; }
  @media (min-width: 32em) {
    .player__timeline {
      width: 50%; } }
  @media (min-width: 32em) {
    .iOS .player__timeline {
      width: 100%; } }
  @media (min-width: 48em) {
    .iOS .player__timeline {
      width: 65%; } }

.player__timeline__progress {
  border-radius: .3rem; }

.player__timeline__time--end,
.player__timeline__time--now {
  display: none; }
  @media (min-width: 48em) {
    .player__timeline__time--end,
    .player__timeline__time--now {
      display: inline-block; } }

.btn__volume {
  background-color: transparent;
  border: 0; }

@media (min-width: 48em) {
  .player__volume {
    margin-left: 1rem; } }

.iOS .player__volume {
  display: none; }

.player__volume__progress, .player__timeline__progress {
  background-color: #702F8A;
  height: 100%;
  display: block; }

.player__volume__bar {
  display: none; }
  @media (min-width: 30em) {
    .player__volume__bar {
      display: inline-block;
      width: 80px; } }

.btn__volume__icon,
.btn__volume__up {
  width: 0.5rem;
  display: inline-block;
  vertical-align: middle; }

.btn__volume__up {
  color: #EFEFEF; }
  .btn__volume--up .btn__volume__up {
    color: #010101; }

.back-to-top {
  position: fixed;
  bottom: 5%;
  right: 5%;
  display: none;
  z-index: 11;
  transition: opacity 0.3s linear; }

.back-to-top.disabled {
  display: none !important; }

.back-to-top button {
  outline: 0; }

.back-to-top.active {
  display: block; }

.block {
  position: relative;
  overflow: hidden; }
  .block .grid {
    align-items: center; }

.block__img {
  position: relative;
  min-height: 10rem; }
  .block__img:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(1, 1, 1, 0.2); }
  .block__img img {
    width: 100%; }

.block__content {
  position: absolute;
  width: 100%;
  bottom: 5rem;
  max-width: 1440px; }
  @media (min-width: 30em) {
    .block__content {
      bottom: auto;
      top: 50%;
      transform: translateY(-50%); } }
  .block-mid .block__content {
    bottom: 1rem;
    top: auto;
    transform: none; }
    @media (min-width: 48em) {
      .block-mid .block__content {
        bottom: 3rem; } }

.block__text {
  display: block;
  width: 100%;
  font-size: 2.375rem;
  color: #ffffff;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.5); }

.block__heading {
  font-weight: 700;
  color: #ffffff;
  font-size: 54px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  line-height: 1;
  margin-bottom: 2rem;
  width: 95%; }
  @media screen and (min-width: 320px) {
    .block__heading {
      font-size: calc(54px + 30 * (100vw - 320px) / 1120); } }
  @media screen and (min-width: 1440px) {
    .block__heading {
      font-size: 84px; } }
  .block-mid .block__heading {
    font-size: 32px; }
    @media screen and (min-width: 320px) {
      .block-mid .block__heading {
        font-size: calc(32px + 28 * (100vw - 320px) / 1120); } }
    @media screen and (min-width: 1440px) {
      .block-mid .block__heading {
        font-size: 60px; } }

blockquote {
  position: relative;
  margin: 0;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }
  blockquote::before, blockquote::after {
    content: '';
    display: table; }
  blockquote::after {
    clear: both; }
  blockquote:before, blockquote:after {
    position: absolute;
    content: ''; }
  blockquote:before {
    display: block;
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23702F8A%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2230%22%20height%3D%2224%22%20viewBox%3D%220%200%2030%2024%22%3E%3Cpath%20d%3D%22M20.8%200l-3.3%2011.2V24H30V11.4h-3.7l2-11.4h-7.5zM3.3%200L0%2011.2V24h12.5V11.4H8.8l2-11.4H3.3z%22%2F%3E%3C%2Fsvg%3E);
    width: 1.5rem;
    height: 1.5rem;
    top: -1.5rem;
    left: 0;
    background-size: auto 80%; }
    .blockquote--imgQuote blockquote:before {
      background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23ffffff%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2230%22%20height%3D%2224%22%20viewBox%3D%220%200%2030%2024%22%3E%3Cpath%20d%3D%22M20.8%200l-3.3%2011.2V24H30V11.4h-3.7l2-11.4h-7.5zM3.3%200L0%2011.2V24h12.5V11.4H8.8l2-11.4H3.3z%22%2F%3E%3C%2Fsvg%3E);
      top: -2.2rem; }
  blockquote:after {
    display: block;
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23702F8A%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2230%22%20height%3D%2224%22%20viewBox%3D%220%200%2030%2024%22%3E%3Cpath%20d%3D%22M20.8%200l-3.3%2011.2V24H30V11.4h-3.7l2-11.4h-7.5zM3.3%200L0%2011.2V24h12.5V11.4H8.8l2-11.4H3.3z%22%2F%3E%3C%2Fsvg%3E);
    width: 1.5rem;
    height: 1.5rem;
    bottom: -1.5rem;
    left: 0;
    background-size: auto 80%;
    transform: rotate(180deg); }
    .blockquote--imgQuote blockquote:after {
      background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23ffffff%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2230%22%20height%3D%2224%22%20viewBox%3D%220%200%2030%2024%22%3E%3Cpath%20d%3D%22M20.8%200l-3.3%2011.2V24H30V11.4h-3.7l2-11.4h-7.5zM3.3%200L0%2011.2V24h12.5V11.4H8.8l2-11.4H3.3z%22%2F%3E%3C%2Fsvg%3E);
      bottom: -2rem; }
  blockquote q {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.416; }
    .blockquote--imgQuote blockquote q {
      font-size: 28px;
      color: #A0D1CA;
      line-height: 1.14;
      margin-bottom: 1rem;
      display: block; }
      @media screen and (min-width: 320px) {
        .blockquote--imgQuote blockquote q {
          font-size: calc(28px + 14 * (100vw - 320px) / 1120); } }
      @media screen and (min-width: 1440px) {
        .blockquote--imgQuote blockquote q {
          font-size: 42px; } }
    blockquote q:before, blockquote q:after {
      content: ''; }
  blockquote cite {
    display: block;
    font-size: 0.9375rem;
    font-weight: 700;
    font-style: normal;
    padding: 0.5rem 0; }

.blockquote--imgQuote::before, .blockquote--imgQuote::after {
  content: '';
  display: table; }

.blockquote--imgQuote::after {
  clear: both; }

@media (min-width: 64em) {
  .blockquote--imgQuote {
    display: flex; } }

.blockquote--imgQuote p {
  color: #ffffff; }

.blockquote--imgQuote cite {
  color: #ffffff;
  font-style: normal;
  font-weight: 400; }

.cite--name {
  text-transform: uppercase;
  font-weight: 700;
  color: #E1D555; }

.blockquote__wrap {
  background-color: #702F8A;
  overflow: hidden;
  padding: 2rem 1rem;
  display: table; }
  @media (min-width: 48em) {
    .blockquote__wrap {
      padding: 1rem 2rem; } }
  @media (min-width: 64em) {
    .blockquote__wrap {
      padding: 1rem 4rem; } }

.blockquote__content {
  display: table-cell;
  vertical-align: middle; }
  .blockquote__content .button__ghost {
    margin-top: 2rem; }

.blockquote__img,
.blockquote__wrap {
  width: 100%; }
  @media (min-width: 64em) {
    .blockquote__img,
    .blockquote__wrap {
      width: 50%; } }

.blockquote__img img {
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'; }

.breadcrumbs {
  font-size: 0.75rem;
  margin-bottom: 1rem; }

.button, .button__link, .button__solid, .button__ghost, .button__overlay, .button__overlay--alt, .form_action button.default {
  font-size: 1.0625rem;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 300ms ease, background-color 300ms ease;
  display: inline-block; }

.button__link {
  color: #9D56CC;
  padding: 0;
  position: relative;
  margin-bottom: 3px;
  white-space: inherit; }
  .button__link:after {
    content: '';
    transition: all 300ms ease;
    backface-visibility: hidden;
    position: absolute;
    bottom: -3px;
    height: 2px;
    width: 100%;
    background: #9D56CC;
    left: 50%;
    transform: translateX(-50%) scaleX(0); }
  .button__link:hover {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: color 300ms ease; }
    .button__link:hover:after {
      transform: translateX(-50%) scaleX(1); }

.button__solid {
  padding: 0.5rem 1rem;
  color: #010101;
  background-color: #E1D555; }
  .button__solid:hover {
    color: #ffffff;
    background-color: #236192; }

.button__ghost {
  padding: 0.5rem 1.5rem;
  color: #ffffff;
  border: 1px solid #ffffff;
  position: relative;
  white-space: normal; }
  @media (min-width: 48em) {
    .button__ghost {
      border: 0;
      border-top: 1px solid #ffffff;
      white-space: nowrap; }
      .button__ghost:hover .button__border span:first-child:before, .button__ghost:hover .button__border span:last-child:before {
        animation: topIn 300ms forwards; }
      .button__ghost:hover .button__border span:first-child:after {
        left: 0;
        opacity: 1; }
      .button__ghost:hover .button__border span:last-child:after {
        right: 0;
        opacity: 1; } }

.button__border {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }
  .button__border span:before, .button__border span:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 0;
    width: 0;
    background-color: #ffffff; }
  .button__border span:first-child:before, .button__border span:last-child:before {
    width: 1px;
    height: 100%;
    top: -100%; }
  .button__border span:first-child:before {
    left: 0; }
  .button__border span:last-child:before {
    right: 0; }
  .button__border span:first-child:after {
    width: 50%;
    height: 1px;
    left: -100%;
    bottom: 0;
    opacity: 0;
    transition: left 300ms ease, opacity 300ms ease; }
  .button__border span:last-child:after {
    width: 50%;
    height: 1px;
    right: -100%;
    bottom: 0;
    opacity: 0;
    transition: right 300ms ease, opacity 300ms ease; }

.button__overlay {
  position: relative;
  color: #ffffff;
  padding: 1.6rem 2.8rem;
  box-shadow: inset 0 0 0 0.4rem #FFC72C;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
  transition: box-shadow 300ms ease; }
  .button__overlay:hover {
    box-shadow: inset 0 0 0 0.8rem #FFC72C; }

.button__overlay--alt {
  padding: 0.5rem 1.5rem;
  color: #ffffff;
  box-shadow: inset 0 0 0 0.1rem #ffffff;
  transition: box-shadow 300ms ease;
  display: inline; }
  .button__overlay--alt:hover, .button__overlay--alt:active {
    box-shadow: inset 0 0 0 0.2rem #ffffff; }

.button__ghost__group {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }
  .button__ghost__group li {
    margin: 1rem; }
    @media (min-width: 48em) {
      .button__ghost__group li {
        margin: 0 0.5rem 0.25rem;
        display: inline-block; } }
    .button__ghost__group li .button__ghost {
      display: block; }

.button__group {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  @media (max-width: 48em) {
    .button__group {
      text-align: center; } }
  .button__group li {
    display: inline-block;
    margin-bottom: 0.25rem;
    margin-right: 0.25rem; }

.button__group--alt {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  .button__group--alt li {
    display: inline-block;
    margin: 0.5rem; }

.button__list li {
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0; }

.button__list li + li:before {
  content: '|';
  padding-right: 0.5rem; }

.button-tag {
  display: inline-block;
  border-radius: 1rem;
  border: 0.1rem solid #53565A;
  padding: 0.35rem 0.5rem;
  line-height: 1;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  color: #53565A;
  margin-bottom: 1.5rem; }

.dropdown {
  position: relative;
  margin-bottom: 2rem; }

.dropdown--alt {
  display: inline-block;
  min-width: 20rem;
  margin-bottom: 0; }

.dropdown__toggle {
  padding: 1rem 3.5rem 1rem 1rem;
  display: block;
  font-size: 1.0625rem;
  font-weight: 700;
  text-decoration: none; }
  .dropdown--alt .dropdown__toggle {
    color: #ffffff;
    background: #012169;
    transition: background 300ms ease; }
    .dropdown--alt .dropdown__toggle:hover {
      background: #236192; }
  .dropdown--active .dropdown__toggle {
    background: #236192; }

.dropdown__icon {
  position: absolute;
  display: block;
  width: 2rem;
  color: #ffffff;
  right: 1rem;
  top: 1.25rem;
  padding: 0.25rem;
  transition: transform 300ms; }
  .dropdown--active .dropdown__icon {
    transform: rotate(180deg); }
  @media (min-width: 64em) {
    .dropdown--subnav .dropdown__icon {
      display: none; } }

.dropdown__list {
  position: absolute;
  left: -999rem;
  width: 100%;
  list-style: none;
  z-index: 1;
  padding-left: 0;
  margin: 0;
  background: #236192; }
  .dropdown--alt .dropdown__list {
    background: #EFEFEF; }
  .dropdown--active .dropdown__list {
    left: 0; }
  .dropdown--subnav .dropdown__list {
    background: #ffffff; }
    @media (min-width: 64em) {
      .dropdown--subnav .dropdown__list {
        position: static;
        left: auto; } }

.dropdown__item {
  margin: 0; }
  .dropdown__item:last-child {
    border-bottom: 0; }

.dropdown__link {
  display: block;
  padding: 1rem;
  color: #010101;
  font-size: 0.9375rem;
  font-weight: 700;
  text-decoration: none; }
  .dropdown__link:hover {
    color: #ffffff;
    background: #012169; }

.dropdown__toggle {
  display: block;
  color: #010101; }

.event {
  margin-top: 2.5rem;
  margin-bottom: 0; }
  .event::before, .event::after {
    content: '';
    display: table; }
  .event::after {
    clear: both; }
  @media (min-width: 48em) {
    .event {
      margin-bottom: 2.5rem; } }
  .event .button__solid {
    margin-top: 2.75rem; }

.event__top-border {
  border-top: 5px solid #236192; }

.event__img {
  margin-bottom: 1rem; }
  @media (min-width: 32em) {
    .event__img {
      float: left;
      margin-right: 1rem; }
      .event--vertical .event__img {
        float: none; } }

.event__block {
  width: 100%;
  float: left; }
  @media (min-width: 32em) {
    .event__block {
      width: 10rem; } }

.event__block--date {
  font-size: 1.25rem;
  font-weight: 700; }

.event__content--title {
  font-weight: 700; }

.event__content--link {
  font-size: 18px;
  margin-top: 0; }
  @media screen and (min-width: 320px) {
    .event__content--link {
      font-size: calc(18px + 6 * (100vw - 320px) / 1120); } }
  @media screen and (min-width: 1440px) {
    .event__content--link {
      font-size: 24px; } }
  .event--vertical .event__content--link {
    margin-top: 0.5rem; }

.event__content {
  overflow: hidden;
  width: 100%; }
  @media (min-width: 32em) {
    .event__content {
      width: auto; } }

.event__item {
  border-bottom: 1px solid #A0D1CA;
  padding: 2rem 0 1rem; }
  .event__item::before, .event__item::after {
    content: '';
    display: table; }
  .event__item::after {
    clear: both; }

.event__divider {
  border-top: 5px solid #236192;
  margin: 0.75rem 0 1rem; }

.faculty {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }
  .faculty::before, .faculty::after {
    content: '';
    display: table; }
  .faculty::after {
    clear: both; }

.faculty--alt {
  margin-top: 5rem;
  margin-bottom: 5rem; }
  .faculty--alt::before, .faculty--alt::after {
    content: '';
    display: table; }
  .faculty--alt::after {
    clear: both; }

@media (min-width: 580px) {
  .faculty--slider-alt {
    display: table; } }

.faculty__img {
  margin-bottom: 1rem; }
  .faculty__slider .faculty__img {
    float: none; }
    @media (min-width: 32em) {
      .faculty__slider .faculty__img {
        float: left;
        margin-right: 1rem; } }
  @media (min-width: 32em) {
    .faculty__img {
      float: left;
      margin-right: 1rem; } }
  .faculty--slider-alt .faculty__img {
    margin-right: 0;
    margin-bottom: 0;
    float: none; }
    @media (min-width: 580px) {
      .faculty--slider-alt .faculty__img {
        display: table-cell; } }

.faculty__img--alt {
  margin-bottom: 1rem; }
  @media (min-width: 48em) {
    .faculty__img--alt {
      float: right; } }

@media (min-width: 580px) {
  .faculty--slider-alt .faculty__image {
    object-fit: cover;
    font-family: 'object-fit: cover;'; } }
  @media (min-width: 580px) and (min-width: 48em) {
    .faculty--slider-alt .faculty__image {
      max-width: 40rem; } }

.faculty__content {
  overflow: hidden;
  margin-right: 1rem; }
  .faculty--slider-alt .faculty__content {
    background: #EFEFEF;
    padding: 1rem; }
    @media (min-width: 64em) {
      .faculty--slider-alt .faculty__content {
        padding: 0 4rem; } }

.faculty__content--alt {
  overflow: hidden; }
  @media (min-width: 48em) {
    .faculty__content--alt {
      padding-right: 2rem; } }

.faculty__content--name {
  margin-top: 0; }
  .faculty__slide .faculty__content--name {
    margin-top: 0; }

.faculty__content--title {
  font-weight: 700;
  margin-bottom: 0.8rem; }
  .faculty--slider-alt .faculty__content--title {
    margin-bottom: 2rem; }

.faculty__content--list {
  padding-left: 0;
  margin: 1.5rem 0;
  list-style: none; }
  .faculty__content--list li {
    margin-bottom: 0; }

.faculty__content--inline {
  display: table-cell;
  vertical-align: middle; }

.faculty__slider-alt__paragraph {
  font-weight: 300;
  font-size: 18px;
  line-height: 1.41; }
  @media screen and (min-width: 320px) {
    .faculty__slider-alt__paragraph {
      font-size: calc(18px + 6 * (100vw - 320px) / 1120); } }
  @media screen and (min-width: 1440px) {
    .faculty__slider-alt__paragraph {
      font-size: 24px; } }

@media (max-width: 32em) {
  .faculty--arrow .slick-arrow {
    top: 30%; } }

@media (min-width: 64em) {
  .faculty__grid__item--2 {
    float: left;
    width: 50%; }
    .faculty__grid__item--2:nth-child(2n+1) {
      clear: both; } }

.faculty__listing {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  .faculty__listing::before, .faculty__listing::after {
    content: '';
    display: table; }
  .faculty__listing::after {
    clear: both; }

.faculty__listing__image {
  margin-right: 2rem;
  margin-bottom: 1rem; }
  @media (min-width: 48em) {
    .faculty__listing__image {
      float: left; } }

.faculty__listing__image--swap {
  margin-bottom: 1rem; }
  @media (min-width: 48em) {
    .faculty__listing__image--swap {
      margin-left: 2rem;
      float: right; } }

.faculty__listing__header {
  overflow: hidden; }
  .faculty__listing__header > .image__heading .image__heading__text {
    color: #010101; }
  .faculty__listing__header h5 {
    margin-top: 0;
    font-size: 1.125rem;
    margin-bottom: 0.5rem; }
  .faculty__listing__header h5 + h5 {
    margin-top: -0.5rem; }
  .faculty__listing__header a {
    display: inline-block; }
    .faculty__listing__header a:hover {
      text-decoration: none; }
      .faculty__listing__header a:hover .image__heading__text {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: color 300ms ease;
        text-decoration: none; }
        .faculty__listing__header a:hover .image__heading__text:after {
          transform: translateX(-50%) scaleX(1); }

.faculty__listing__contact {
  margin: 1rem 0; }

.faculty__listing__title {
  margin-top: -0.5rem;
  font-size: 1.125rem; }

.form__input.faculty__search {
  width: 100%;
  margin-bottom: 1rem; }
  @media (min-width: 32em) {
    .form__input.faculty__search {
      width: 50%;
      float: left;
      margin-bottom: 2rem; } }

.faculty__submit {
  width: auto; }
  @media (min-width: 32em) {
    .faculty__submit {
      float: left;
      clear: none;
      padding-top: .75rem;
      padding-bottom: .75rem;
      margin-left: 1rem;
      width: auto; } }

.feed__heading {
  margin-top: 1rem; }

.feed__item a {
  display: block; }

.figure__donut {
  position: relative;
  height: 12rem; }
  .figure__donut circle {
    fill: none; }

.figure__circle {
  position: absolute;
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  z-index: -1;
  transform: translateX(-50%); }

.figure__circle--wrap {
  text-align: center;
  height: 12rem; }

.svgdonut {
  display: block;
  margin: 0 auto;
  width: 170px;
  height: 100%; }

.middle__center, .figure__circle, .middle__circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.middle__circle {
  border-radius: 50%;
  height: 12rem;
  width: 12rem; }

.circle-back {
  stroke: #DDEFEC;
  stroke-width: 6px; }

.circle-front {
  stroke: #ED8B00;
  stroke-width: 6px;
  /* 2 * Math.PI[3.14159] * radius + 1  */
  stroke-dasharray: 503px;
  stroke-dashoffset: 503px;
  transition: stroke-dashoffset .5s ease; }

.figure__count__number--wrap {
  text-align: center;
  padding: 0; }

.figure__count--number,
.figure__count--number-alt {
  font-weight: 300;
  color: #53565A; }

.figure__count--number {
  font-size: 3.75rem; }

.figure__count--number-alt {
  font-size: 6.25rem;
  line-height: 1.2;
  font-size: 60px; }
  .figure__count--number-alt small {
    display: block; }
    @media (min-width: 48em) {
      .figure__count--number-alt small {
        font-size: 50%;
        margin-top: -2.5rem; } }
  @media screen and (min-width: 320px) {
    .figure__count--number-alt {
      font-size: calc(60px + 40 * (100vw - 320px) / 1120); } }
  @media screen and (min-width: 1440px) {
    .figure__count--number-alt {
      font-size: 100px; } }

.figure__count--small {
  font-size: 25%;
  line-height: 1;
  margin-top: 1rem; }

.figure__count--text {
  font-size: 1.75rem;
  color: #53565A;
  line-height: 1.14;
  margin-bottom: 0.5rem; }

.figure__count--subtext {
  font-size: 0.9375rem; }

[class^="figure__icon"] {
  display: block;
  width: 14rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  [class^="figure__icon"] img {
    max-height: 10rem;
    margin: 0 auto; }

.figure__icon--teal {
  color: #56919A; }

.figure__icon--maroon {
  color: #651D32; }

.figure__icon--blue-alt {
  color: #135888; }

.figure__icon--orange {
  color: #ED8B00; }

.figure__slider {
  padding: 2rem 0 0;
  min-height: 21rem; }
  @media (min-width: 48em) {
    .figure__slider {
      padding: 8rem 0; } }

.figure__slider--alt, .figure__slider,
.figure__slider--alt .slick-track,
.figure__slider .slick-track {
  min-height: 21rem; }

.figure__slide__item {
  padding: 0 3rem;
  margin: 0.25rem; }

.slider__nav--figure .slick-disabled {
  opacity: .3;
  pointer-events: none; }
  .slider__nav--figure .slick-disabled:hover {
    background-color: #E1D555;
    color: #010101;
    cursor: default; }

.lbl-sm {
  color: #236192;
  font-size: 0.9375rem;
  line-height: 1;
  margin-top: 0.5rem; }

.green {
  background-color: #DDEFEC; }

.footer {
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; }
  .footer a {
    color: #ffffff;
    text-decoration: none; }

.footer__main {
  background: #702F8A;
  padding: 5rem 1rem;
  overflow: hidden; }
  @media (min-width: 48em) {
    .footer__main .grid__item--4 {
      padding-top: 0; } }

.footer__logo {
  display: inline-block;
  font-size: 2.5rem;
  line-height: 1;
  margin-bottom: 1rem; }

.footer__bottom__logo {
  display: block;
  width: 6rem;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem; }
  @media (min-width: 64em) {
    .footer__bottom__logo {
      margin: 0 5rem 0 1rem; } }
  @media (min-width: 80em) {
    .footer__bottom__logo {
      margin: 0 5rem 0 0; } }

.footer__address {
  font-style: normal;
  font-size: 1rem;
  margin-bottom: 2rem; }

.footer__main__link__list,
.footer__link__list {
  margin: 0;
  padding: 0;
  list-style: none; }

@media (min-width: 80em) {
  .footer__main__link__list {
    padding-right: 2rem; } }

.footer__main__link__item {
  margin-bottom: 1.25rem; }

.footer__link__list {
  float: left; }
  .footer__link__list:first-child {
    margin-right: 0.8rem; }
    @media (min-width: 32em) {
      .footer__link__list:first-child {
        margin-right: 1.5rem; } }
    @media (min-width: 64em) {
      .footer__link__list:first-child {
        margin-right: 0; } }
  @media (min-width: 64em) {
    .footer__link__list {
      float: none; } }

.footer__main__link {
  font-size: 1.5rem;
  line-height: 1; }

.footer__main__link:hover {
  background-image: linear-gradient(currentColor, currentColor);
  background-repeat: repeat-x;
  background-position: 0 90%;
  background-size: 100% 0.0625rem; }

.footer__link:hover {
  text-decoration: underline; }

.footer__link__item {
  margin-bottom: 0.5rem; }

.footer__heading__item {
  font-size: 0.75rem;
  text-transform: uppercase;
  margin-top: 2rem; }
  @media (min-width: 64em) {
    .footer__heading__item {
      margin-top: 0; } }

.footer__link {
  font-size: 1rem;
  font-weight: 700; }

.footer__social__link {
  display: inline-block;
  width: 1.25rem;
  vertical-align: sub;
  margin-right: 0.5rem; }

.footer__main__grid__item {
  width: 100%;
  padding: 0 1rem;
  text-align: center; }
  @media (min-width: 30em) {
    .footer__main__grid__item {
      text-align: left;
      float: left;
      width: 50%; }
      .footer__main__grid__item:nth-child(n) {
        clear: none; }
      .footer__main__grid__item:nth-child(4n+1) {
        clear: both; } }
  @media (min-width: 64em) {
    .footer__main__grid__item {
      text-align: left;
      float: left;
      width: 25%; }
      .footer__main__grid__item:nth-child(n) {
        clear: none; }
      .footer__main__grid__item:nth-child(4n+1) {
        clear: both; }
      .footer__main__grid__item:nth-child(1) {
        width: 22%; } }

.footer__bottom {
  background: #012169;
  padding: 1rem 0; }
  @media (min-width: 64em) {
    .footer__bottom {
      padding: 0;
      text-align: center; } }

@media (min-width: 64em) {
  .footer__bottom__wrap {
    display: flex;
    align-items: center; }
    .footer__bottom__wrap::before, .footer__bottom__wrap::after {
      content: '';
      display: table; }
    .footer__bottom__wrap::after {
      clear: both; } }

.footer__bottom__link {
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;
  display: block; }
  @media (min-width: 64em) {
    .footer__bottom__link {
      padding: 1rem;
      display: inline-block; } }
  .footer__bottom__link:hover {
    text-decoration: underline; }

.OU-edit {
  cursor: default; }

.footer__bottom__text {
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;
  display: block; }
  @media (min-width: 64em) {
    .footer__bottom__text {
      padding: 1rem;
      display: inline-block; } }

.form {
  padding: 2rem 0;
  margin-bottom: 2rem; }
  .form::before, .form::after {
    content: '';
    display: table; }
  .form::after {
    clear: both; }
  @media (max-width: 48em) {
    .form {
      padding-left: 1rem;
      padding-right: 1rem; } }
  * + .form {
    margin-top: 2rem; }

.form__label {
  display: block;
  margin-top: 0;
  margin-bottom: 0.5rem; }

.form__label--required {
  color: #DA291C; }

.form__input,
.form__textarea {
  background-color: #ffffff;
  border: 0.3rem solid #53565A;
  width: 100%;
  margin-bottom: 2rem;
  line-height: 1.56;
  padding: 0.25rem 3rem 0.25rem 0.5rem; }
  .form__input:focus,
  .form__textarea:focus {
    border: 0.3rem solid #236192;
    outline: 0; }

.form__input {
  height: 2.8rem; }

.form__input--success {
  border: .3rem solid #388407; }

.form__input--error {
  border: 0.3rem solid #DA291C; }

.form__icon__placement {
  position: relative; }

[class^="form__input__icon"] {
  display: block;
  width: 1.5rem;
  position: absolute;
  right: 0.75rem;
  top: 0.65rem; }

.form__input__icon--error {
  color: #DA291C; }

.form__select {
  overflow: hidden;
  display: block;
  position: relative;
  margin-bottom: 2rem;
  background-color: #ffffff; }
  .form__select select {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0;
    z-index: 1;
    padding-left: 1rem;
    color: #53565A;
    font-size: 1.1875rem;
    border: 0.3rem solid #53565A;
    border-radius: 0; }
    .form__select select:focus {
      border: 0.3rem solid #236192;
      outline: 0; }

.form__select__text {
  width: 100%;
  height: 3.5rem;
  padding: 0 1rem;
  background-color: #ffffff;
  margin: 0;
  float: left;
  width: calc(100% - 3.5rem);
  line-height: 3.25rem;
  padding: 0 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: transparent; }

.form__select__arrow {
  position: absolute;
  right: 0;
  top: 0;
  width: 3.5rem;
  height: 3.5rem;
  padding: 1.5rem 1rem 1rem 1rem;
  background: #ffffff;
  color: #53565A;
  display: inline-block; }
  select:focus ~ .form__select__arrow {
    transform: rotate(180deg); }

.form__checkbox {
  display: block;
  position: relative;
  margin: 0.5rem 0;
  padding-left: 2.5rem;
  cursor: pointer; }
  .form__checkbox:before {
    position: absolute;
    content: '';
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%2353565A%22%20width%3D%2236%22%20height%3D%2236%22%20viewBox%3D%220%200%2036%2036%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M4%204h28v28H4V4zm28-4H4C1.8%200%200%201.8%200%204v28c0%202.2%201.8%204%204%204h28c2.2%200%204-1.8%204-4V4c0-2.2-1.8-4-4-4z%22%2F%3E%3C%2Fsvg%3E);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: auto 90%; }

.form__checkbox[aria-checked='true']:before {
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23236192%22%20width%3D%2236%22%20height%3D%2236%22%20viewBox%3D%220%200%2036%2036%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M32%200H4C1.78%200%200%201.8%200%204v28c0%202.2%201.78%204%204%204h28c2.22%200%204-1.8%204-4V4c0-2.2-1.78-4-4-4zM14%2028L4%2018l2.82-2.82L14%2022.34%2029.18%207.16%2032%2010%2014%2028z%22%2F%3E%3C%2Fsvg%3E); }

.form__radio {
  display: block;
  position: relative;
  margin: 0.5rem 0;
  padding-left: 2.5rem;
  cursor: pointer; }
  .form__radio:before {
    position: absolute;
    content: '';
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%2353565A%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M20%200C8.96%200%200%208.96%200%2020s8.96%2020%2020%2020%2020-8.96%2020-20S31.04%200%2020%200zm0%2036c-8.84%200-16-7.16-16-16S11.16%204%2020%204s16%207.16%2016%2016-7.16%2016-16%2016z%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    top: 0;
    left: 0;
    background-size: auto 100%; }

.form__radio[aria-checked='true']:before {
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23236192%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M20%2010c-5.52%200-10%204.48-10%2010s4.48%2010%2010%2010%2010-4.48%2010-10-4.48-10-10-10zm0-10C8.96%200%200%208.96%200%2020s8.96%2020%2020%2020%2020-8.96%2020-20S31.04%200%2020%200zm0%2036c-8.84%200-16-7.16-16-16S11.16%204%2020%204s16%207.16%2016%2016-7.16%2016-16%2016z%22%2F%3E%3C%2Fsvg%3E); }

.form_pages select {
  -moz-appearance: menulist;
  /* Firefox 40+ */
  -webkit-appearance: menulist;
  /* Chrome 45+ */ }

.form_pages button,
.form_pages input,
.form_pages select,
.form_pages textarea {
  background-color: initial;
  border-style: solid;
  color: black;
  font-size: 1em;
  margin: 0;
  border: 1px solid black; }

.form_pages input,
.form_pages textarea {
  background-color: #ffffff;
  border: 0.3rem solid #53565A;
  width: 100%;
  margin-bottom: 2rem;
  line-height: 1.56;
  padding: 0.25rem 3rem 0.25rem 0.5rem; }
  .form_pages input:focus,
  .form_pages textarea:focus {
    border: 0.3rem solid #236192;
    outline: 0; }
  .form_pages input select,
  .form_pages textarea select {
    height: 3.5rem;
    border: 0.3rem solid #53565A; }

.form_pages .form_responses select {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding-left: 1rem;
  color: #53565a;
  font-size: 1.1875rem;
  border: .3rem solid #53565a;
  border-radius: 0; }

.form_pages select[multiple] {
  -webkit-appearance: none; }

.form_pages input[type=checkbox], .form_pages input[type=radio] {
  width: inherit;
  margin: inherit; }

.form_action button.default {
  padding: 0.5rem 1rem;
  color: #010101;
  background-color: #E1D555; }
  .form_action button.default:hover {
    color: #ffffff;
    background-color: #236192; }

.form__inline__group {
  display: inline-block; }

.grid {
  display: flex;
  flex-flow: row wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.grid--center {
  justify-content: center; }

@media (min-width: 64em) {
  .grid--wide {
    margin-left: -3rem;
    margin-right: -3rem; } }

@media (min-width: 64em) {
  .grid--narrow {
    margin-right: -0.5rem;
    margin-left: -0.5rem; } }

.grid--slim {
  margin-right: -0.25rem;
  margin-left: -0.25rem; }

.grid__item {
  padding: 1rem; }

.grid__item--no-v-padding {
  padding: 0 1rem; }

.grid__item--match {
  flex-flow: row wrap; }
  .grid__item--match > * {
    display: flex;
    flex-flow: column wrap; }

@media (min-width: 64em) {
  .grid__item--narrow {
    padding: 0 0.5rem; } }

.grid__item--slim {
  padding: 0 0.25rem; }

.grid__item--wide {
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 48em) {
    .grid__item--wide {
      padding: 0 0.5rem; } }
  @media (min-width: 64em) {
    .grid__item--wide {
      padding: 0 3rem; } }

.grid__item--2 {
  width: 100%; }
  @media (min-width: 48em) {
    .grid__item--2 {
      float: left;
      width: 50%; }
      .grid__item--2:nth-child(2n+1) {
        clear: both; } }

.grid__item--3 {
  width: 100%; }
  @media (min-width: 48em) {
    .grid__item--3 {
      float: left;
      width: 33.333%; }
      .grid__item--3:nth-child(3n+1) {
        clear: both; } }

@media (min-width: 64em) {
  .grid__item--3-2 {
    float: left;
    width: 66.666%; } }

@media (min-width: 48em) {
  .grid__item--4 {
    float: left;
    width: 50%; }
    .grid__item--4:nth-child(2n+1) {
      clear: both; } }

@media (min-width: 64em) {
  .grid__item--4 {
    width: 25%; }
    .grid__item--4:nth-child(n) {
      clear: none; }
    .grid__item--4:nth-child(4n+1) {
      clear: both; } }

@media (min-width: 32em) {
  .grid__item--5 {
    width: 100%;
    float: left; }
    .grid__item--5:nth-child(2n+1) {
      clear: both; } }

@media (min-width: 48em) {
  .grid__item--5 {
    width: 100%; }
    .grid__item--5:nth-child(n) {
      clear: none; }
    .grid__item--5:nth-child(3n+1) {
      clear: both; } }

@media (min-width: 64em) {
  .grid__item--5 {
    width: 20%; }
    .grid__item--5:nth-child(n) {
      clear: none; }
    .grid__item--5:nth-child(5n+1) {
      clear: both; } }

header {
  position: relative;
  z-index: 20; }

.header {
  position: absolute;
  background-color: #702F8A;
  width: 100%; }

.header--collapsed {
  position: fixed;
  top: 0; }

.header__small {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: #702F8A;
  position: relative;
  z-index: 1; }
  @media (min-width: 1025px) {
    .header__small {
      display: none; } }

.header__menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 5rem; }

.header__logo {
  font-size: 2.25rem;
  color: #ffffff;
  text-decoration: none; }

.hero {
  position: relative; }

@media (min-width: 32em) {
  .hero__image {
    position: absolute;
    width: 100%;
    height: 100%; } }

.hero__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'; }

.hero__wrap {
  position: absolute;
  top: 0;
  padding-top: 1rem;
  padding-bottom: 5rem; }
  @media (min-width: 32em) {
    .hero__wrap {
      position: relative; } }
  @media (min-width: 32em) {
    .hero__wrap {
      padding-top: 5rem;
      min-height: 28.125rem; } }

.hero__logo {
  width: 8rem;
  color: #ffffff;
  display: inline-block;
  margin-right: 1.75rem; }
  .internal .hero__logo {
    display: none; }
  @media (min-width: 32em) {
    .hero__logo {
      width: 10rem; } }
  @media (min-width: 64em) {
    .hero__logo {
      width: 5rem; } }
  .home .hero__logo {
    display: block;
    width: 8rem;
    margin-bottom: 3rem; }
    @media (min-width: 32em) {
      .home .hero__logo {
        width: 10rem; } }

.hero__tagline {
  display: none; }
  @media (min-width: 64em) {
    .hero__tagline {
      position: relative;
      display: inline-block;
      background-color: rgba(112, 47, 138, 0.85);
      padding: 1rem 1.7rem;
      font-size: 3rem;
      color: #ffffff;
      text-decoration: none; }
      .internal .hero__tagline {
        display: none; }
      .home .hero__tagline {
        font-size: 5rem; } }

.home__heading {
  font-size: 30px;
  color: #651D32; }
  @media screen and (min-width: 320px) {
    .home__heading {
      font-size: calc(30px + 28 * (100vw - 320px) / 1120); } }
  @media screen and (min-width: 1440px) {
    .home__heading {
      font-size: 58px; } }

.home__heading__section {
  padding-top: 2rem; }
  @media (min-width: 48em) {
    .home__heading__section {
      padding-top: 5rem; } }
  @media (min-width: 64em) {
    .home__heading__section p {
      width: 50%; } }

.animateInHome {
  transform: translateX(-8rem);
  transition: transform 300ms ease-in-out; }

.animatedInHome {
  transform: none; }

@media (max-width: 48em) {
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20%); }
    50% {
      opacity: 1; }
    100% {
      transform: translateY(35%);
      opacity: 1; } } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(0%); }
  50% {
    opacity: 1; }
  100% {
    transform: translateY(25%);
    opacity: 1; } }

.home__hero__arrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  opacity: 0;
  z-index: 1;
  animation: fadeInDown 1.5s ease forwards; }

.home__hero__arrow--icon {
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23012169%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%2240%22%20viewBox%3D%220%200%2014%2040%22%3E%3Cpath%20d%3D%22M12.8%2031.8l-4.9%204.8V0H6.1v36.6l-4.9-4.8L0%2033l7%207%207-7z%22%2F%3E%3C%2Fsvg%3E);
  display: block;
  width: 1rem;
  height: 2.5rem;
  background-size: auto 100%;
  margin-left: -10px;
  animation: fadeInDown 1.5s ease forwards; }
  @media (min-width: 48em) {
    .home__hero__arrow--icon {
      background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23012169%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%2299%22%20viewBox%3D%220%200%2014%2099%22%3E%3Cpath%20d%3D%22M6%200h2v60H6z%22%2F%3E%3Cpath%20d%3D%22M8%2095.5V59H6v36.5l-4.8-4.8L0%2092l7%207%207-7-1.2-1.2L8%2095.5z%22%2F%3E%3C%2Fsvg%3E);
      width: 1.5rem;
      height: 7.5rem; } }

.tcon {
  appearance: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  transition: 0.3s;
  user-select: none;
  width: 32px;
  background: transparent;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent; }
  .tcon > * {
    display: block; }
  .tcon::-moz-focus-inner {
    border: 0; }

.tcon-menu__lines {
  display: inline-block;
  height: 2px;
  width: 32px;
  border-radius: 0;
  transition: 0.3s;
  background: #ffffff;
  position: relative;
  width: 30px; }
  .tcon-menu__lines::before, .tcon-menu__lines::after {
    display: inline-block;
    height: 2px;
    width: 32px;
    border-radius: 0;
    transition: 0.3s;
    background: #ffffff;
    height: 2px;
    content: '';
    position: absolute;
    left: 0;
    transform-origin: 2.28571px center;
    width: 100%; }
  .tcon-menu__lines::before {
    top: 9.14286px; }
  .tcon-menu__lines::after {
    top: -9.14286px; }
  .tcon-transform .tcon-menu__lines {
    transform: scale3d(0.6, 0.6, 0.6); }

.tcon-menu--xcross {
  width: auto; }
  .tcon-menu--xcross.tcon-transform .tcon-menu__lines {
    background: transparent; }
    .tcon-menu--xcross.tcon-transform .tcon-menu__lines::before, .tcon-menu--xcross.tcon-transform .tcon-menu__lines::after {
      transform-origin: 50% 50%;
      top: 0;
      width: 32px;
      height: 4px; }
    .tcon-menu--xcross.tcon-transform .tcon-menu__lines::before {
      transform: rotate3d(0, 0, 1, 45deg); }
    .tcon-menu--xcross.tcon-transform .tcon-menu__lines::after {
      transform: rotate3d(0, 0, 1, -45deg); }

.tcon-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  .tcon-visuallyhidden:active, .tcon-visuallyhidden:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto; }

.tcon-search__item {
  display: inline-block;
  height: 24px;
  width: 24px;
  border-radius: 0;
  transition: 0.3s;
  background: transparent;
  position: relative;
  transform: rotate(45deg); }
  .tcon-search__item::before, .tcon-search__item::after {
    display: inline-block;
    height: 24px;
    width: 24px;
    border-radius: 0;
    transition: 0.3s;
    background: transparent;
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    margin: auto; }
  .tcon-search__item::before {
    height: 3.2px;
    right: 0;
    background: white;
    border-radius: 0; }
  .tcon-search__item::after {
    left: 0;
    background: #702F8A;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 2px solid white;
    transform-origin: 50% 50%; }
    .nav__search .tcon-search__item::after {
      background: #702F8A; }

.tcon-search--xcross.tcon-transform {
  transform: scale3d(0.8, 0.8, 0.8); }
  .tcon-search--xcross.tcon-transform .tcon-search__item::before, .tcon-search--xcross.tcon-transform .tcon-search__item::after {
    border-radius: 0;
    background: white; }
  .tcon-search--xcross.tcon-transform .tcon-search__item::after {
    height: 3.2px;
    width: 100%;
    top: 0;
    right: 0;
    border: 0;
    transform: rotate3d(0, 0, 1, 90deg); }

.circle__arrow {
  display: block;
  width: 2rem;
  height: 2rem;
  background: #E1D555;
  padding: 0 0.5rem;
  border-radius: 50%;
  transition: background-color 300ms ease, color 300ms ease; }
  .circle__arrow:hover, .circle__arrow:focus, .circle__arrow:active {
    background: #236192;
    color: #ffffff; }

.square__arrow {
  display: block;
  width: 3rem;
  height: 3rem;
  background: #E1D555;
  padding: 0.8rem;
  color: #010101;
  transition: background-color 300ms ease, color 300ms ease; }
  .square__arrow:hover, .square__arrow:focus, .square__arrow:active {
    background: #236192;
    color: #ffffff; }

.circle__icon {
  display: block;
  width: 1.75rem;
  height: 1.75rem;
  background: #702F8A;
  border-radius: 50%;
  color: #ffffff;
  transition: background-color 300ms ease;
  padding: .45rem; }
  .circle__icon:hover, .circle__icon:focus, .circle__icon:active {
    background: #9D56CC; }

.play__icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 3.5rem;
  color: #702F8A; }

.social__icon {
  display: inline-block;
  vertical-align: middle;
  width: 1.5rem;
  color: #53565A;
  transition: color 300ms ease; }

.social__icon--alt {
  display: inline-block;
  vertical-align: middle;
  width: 1.8rem;
  color: #53565A;
  transition: color 300ms ease; }

.social__icon__list {
  margin: 0;
  padding: 0;
  list-style: none; }
  .social__icon__list li {
    display: inline-block;
    margin: 0 0.25rem 0; }

.facebook:hover {
  color: #3b5998; }

.instagram:hover {
  color: #bc2a8d; }

.twitter:hover {
  color: #00aced; }

.youtube:hover {
  color: #bb0000; }

.linkedin:hover {
  color: #007bb6; }

.image__item {
  padding-bottom: 2rem; }
  @media (min-width: 48em) {
    .image__item {
      padding-bottom: 4rem; } }
  .image__item a {
    display: block; }
  .image__item p a {
    display: inline; }
    .image__item p a:hover {
      text-decoration: underline; }
  .image__item img {
    margin-bottom: 1.8rem; }
  .image__item a:hover {
    text-decoration: none; }
    .image__item a:hover .image__heading__text {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: color 300ms ease;
      text-decoration: none; }
      .image__item a:hover .image__heading__text:after {
        transform: translateX(-50%) scaleX(1); }

.image__caption {
  padding: 0.5rem 0;
  border-bottom: 0.0625rem solid #236192; }

.image__heading {
  margin: 0 0 0.5rem;
  overflow: hidden;
  padding-bottom: 5px;
  display: inline-block; }

.image__heading__text {
  color: #9D56CC;
  padding: 0;
  position: relative;
  margin-bottom: 5px; }
  .image__heading__text:after {
    content: '';
    transition: all 300ms ease;
    backface-visibility: hidden;
    position: absolute;
    bottom: -5px;
    height: 2px;
    width: 100%;
    background: #9D56CC;
    left: 50%;
    transform: translateX(-50%) scaleX(0); }

.image__caption__heading {
  font-size: 0.9375rem;
  font-weight: 700;
  margin: 0.8rem 0 0.5rem; }

.juicer {
  padding: 2rem 1rem; }
  .juicer .j-poster img {
    display: inline-block; }

.juicer-feed .j-paginate {
  transition: all 0.2s ease-in-out;
  display: inline-block;
  border: 0 !important;
  font-size: 17px !important;
  font-weight: bold;
  letter-spacing: 0.5px !important;
  color: #010101 !important;
  cursor: pointer;
  background-color: #E1D555 !important;
  width: 125px !important;
  text-transform: none !important;
  padding: 0.5rem 1rem !important; }
  .juicer-feed .j-paginate:hover {
    color: #ffffff !important;
    background-color: #236192 !important; }

.j-meta a {
  color: #53565A !important; }
  .j-meta a:hover {
    color: #236192 !important; }

.j-poster-meta {
  margin-top: 0 !important; }

.media {
  margin-bottom: 2rem; }

.media__item {
  display: inline-block;
  position: relative; }
  .media__item img {
    width: 100%; }
  @media (min-width: 48em) {
    .media__item {
      display: block; } }

.media__link:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: background 300ms ease; }

.media__link:hover:before {
  background: rgba(0, 0, 0, 0.4); }

.media__link:hover .circle__icon {
  background: #9D56CC; }

.media__link:hover .play__icon {
  color: #9D56CC; }

.media__caption {
  margin-top: 1rem; }

.media__caption--large {
  font-size: 1.375rem;
  font-weight: 300; }

.media__icon {
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem; }

.media__icon--alt {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  left: auto; }

.media__gallery {
  visibility: hidden;
  display: none; }

.media__home__slider {
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem; }
  @media (min-width: 32em) {
    .media__home__slider .slick-next, .media__home__slider .slick-prev {
      top: 30%;
      transform: translateY(-30%); } }

.media__home__slider__item {
  display: flex !important; }
  @media (min-width: 54em) {
    .media__home__slider__item {
      opacity: .3; } }

.slick-center {
  opacity: 1 !important; }

.media__home__slide {
  width: 50%;
  margin: 0 1.5rem; }
  @media (min-width: 49em) and (max-width: 54em) {
    .media__home__slide {
      opacity: .3; } }
  @media (min-width: 32em) {
    .media__home__slide {
      margin: 0 1rem; } }

.media__feature {
  padding: 0 1rem;
  max-width: 1440px;
  margin: 0 auto; }
  @media (min-width: 48em) {
    .media__feature {
      padding: 1.2rem; } }
  .media__feature .grid__item {
    padding: 0; }
    @media (min-width: 48em) {
      .media__feature .grid__item {
        padding: 0 1rem; } }

.media__feature__item {
  position: relative;
  overflow: hidden; }
  .media__feature__item::before, .media__feature__item::after {
    content: '';
    display: table; }
  .media__feature__item::after {
    clear: both; }
  .grid__item:nth-of-type(1) .media__feature__item {
    margin-bottom: 2rem; }
  @media (min-width: 64em) {
    .grid__item:nth-of-type(1) .media__feature__item {
      margin-bottom: 0; } }
  .media__feature__item img {
    width: 100%; }

.media__feature__content {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  color: #ffffff;
  text-align: center;
  padding: 3rem 0 2rem;
  height: 18rem;
  margin-bottom: -10rem;
  transition: height 300ms ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(112, 47, 138, 0.85);
  border-radius: 100% 100% 0px 0px;
  width: 145%;
  margin-left: -22%; }
  .media__mobile__animated .media__feature__content {
    height: 100%;
    width: 100%;
    border-radius: 0;
    margin-bottom: 0;
    padding: 3rem 6rem;
    margin-left: 0; }
  .media__feature__content:before {
    position: inherit;
    content: '';
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23ffffff%22%20width%3D%2218%22%20height%3D%2217%22%20viewBox%3D%225%205%2018%2017%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M7.41%2015.41L13%209.83V22h2V9.83l5.59%205.58L22%2014l-8-8-8%208z%22%2F%3E%3C%2Fsvg%3E);
    width: 1rem;
    height: 1rem;
    top: 1rem; }
    .media__mobile__animated .media__feature__content:before {
      transform: rotate(180deg); }
    @media (min-width: 48em) {
      .media__feature__content:before {
        background-image: none; } }
  @media (min-width: 48em) {
    .media__feature__content {
      transition: margin-bottom 1s ease, width 1s ease, border-radius 2s ease;
      width: auto;
      margin-left: auto;
      padding: 2rem;
      margin-bottom: -230px;
      border-radius: 0;
      height: auto; } }

@media (min-width: 64em) {
  .media__feature__content__wrapper {
    position: relative; } }

.media__mobile__animated .media__feature__content__wrapper {
  position: absolute;
  bottom: 2rem;
  left: 1rem;
  right: 1rem; }

.media__feature__content.media__animated {
  border-radius: 100% 100% 0px 0px;
  width: 140%;
  margin-left: -20%;
  margin-bottom: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
  padding-bottom: 100px; }
  @media screen and (min-width: 320px) {
    .media__feature__content.media__animated {
      padding-left: calc(100px + 100 * (100vw - 320px) / 1120); } }
  @media screen and (min-width: 1440px) {
    .media__feature__content.media__animated {
      padding-left: 200px; } }
  @media screen and (min-width: 320px) {
    .media__feature__content.media__animated {
      padding-right: calc(100px + 100 * (100vw - 320px) / 1120); } }
  @media screen and (min-width: 1440px) {
    .media__feature__content.media__animated {
      padding-right: 200px; } }
  @media screen and (min-width: 320px) {
    .media__feature__content.media__animated {
      padding-top: calc(50px + 0 * (100vw - 320px) / 1120); } }
  @media screen and (min-width: 1440px) {
    .media__feature__content.media__animated {
      padding-top: 50px; } }
  @media screen and (min-width: 320px) {
    .media__feature__content.media__animated {
      padding-bottom: calc(100px + 0 * (100vw - 320px) / 1120); } }
  @media screen and (min-width: 1440px) {
    .media__feature__content.media__animated {
      padding-bottom: 100px; } }
  @media (min-width: 48em) {
    .media__feature__content.media__animated {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 50px;
      padding-bottom: 2rem; } }
  @media screen and (min-width: 48em) and (min-width: 320px) {
    .media__feature__content.media__animated {
      padding-left: calc(0px + 200 * (100vw - 320px) / 1120); } }
  @media screen and (min-width: 48em) and (min-width: 1440px) {
    .media__feature__content.media__animated {
      padding-left: 200px; } }
  @media screen and (min-width: 48em) and (min-width: 320px) {
    .media__feature__content.media__animated {
      padding-right: calc(0px + 200 * (100vw - 320px) / 1120); } }
  @media screen and (min-width: 48em) and (min-width: 1440px) {
    .media__feature__content.media__animated {
      padding-right: 200px; } }
  @media screen and (min-width: 48em) and (min-width: 320px) {
    .media__feature__content.media__animated {
      padding-top: calc(50px + 0 * (100vw - 320px) / 1120); } }
  @media screen and (min-width: 48em) and (min-width: 1440px) {
    .media__feature__content.media__animated {
      padding-top: 50px; } }
  @media (min-width: 64em) {
    .media__feature__content.media__animated {
      padding-left: 100px;
      padding-right: 100px;
      padding-top: 50px;
      padding-bottom: 2rem; } }
  @media screen and (min-width: 64em) and (min-width: 320px) {
    .media__feature__content.media__animated {
      padding-left: calc(100px + 100 * (100vw - 320px) / 1120); } }
  @media screen and (min-width: 64em) and (min-width: 1440px) {
    .media__feature__content.media__animated {
      padding-left: 200px; } }
  @media screen and (min-width: 64em) and (min-width: 320px) {
    .media__feature__content.media__animated {
      padding-right: calc(100px + 100 * (100vw - 320px) / 1120); } }
  @media screen and (min-width: 64em) and (min-width: 1440px) {
    .media__feature__content.media__animated {
      padding-right: 200px; } }
  @media screen and (min-width: 64em) and (min-width: 320px) {
    .media__feature__content.media__animated {
      padding-top: calc(50px + 0 * (100vw - 320px) / 1120); } }
  @media screen and (min-width: 64em) and (min-width: 1440px) {
    .media__feature__content.media__animated {
      padding-top: 50px; } }
  .media__feature__content.media__animated .wrap--relative {
    margin-bottom: -4rem; }

.media__feature__icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10; }
  @media (max-width: 48em) {
    .media__mobile__animated .media__feature__icon .circle__icon {
      background-color: #ffffff;
      color: #702F8A; } }

@media (min-width: 48em) {
  .media__animate {
    visibility: hidden; } }

@media (min-width: 48em) {
  .media__animated {
    visibility: visible !important;
    animation-duration: 1s;
    animation-fill-mode: both; } }

.media__feature--name {
  font-size: 1.375rem; }

.media__feature--title {
  font-size: 0.9375rem;
  font-weight: 700; }

.media__feature--paragraph {
  font-size: 1.375rem;
  font-weight: 700;
  margin: 2rem auto;
  transition: none; }

@media (max-width: 32em) {
  .media__slider .slick-next,
  .media__slider .slick-prev {
    top: 35%;
    transform: translateY(-35%); } }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.modal__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75); }

.modal__container {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.modal__status {
  text-align: center; }

.modal__item {
  display: block;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto; }

.modal__video {
  position: relative;
  padding-bottom: 56.25%;
  background: #000; }

.modal__audio {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

.modal__embed {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0; }

.modal__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.modal__nav--previous {
  left: 0; }

.modal__nav--next {
  right: 0; }

.modal__close {
  position: absolute;
  top: 0;
  right: 0; }

.modal {
  z-index: 1002; }

.modal__container {
  max-width: 90rem;
  margin: auto;
  padding: 3rem 1rem; }
  @media (min-width: 48em) {
    .modal__container {
      padding: 3rem 2rem; } }
  @media (min-width: 80em) {
    .modal__container {
      padding: 3rem; } }

.modal__overlay,
.modal__status,
.modal__controls,
.modal__close {
  transition: opacity 320ms ease;
  opacity: 0; }

.modal--active .modal__overlay,
.modal--active .modal__controls,
.modal--active .modal__close,
.modal--loading .modal__status,
.modal--loaded .modal__status {
  opacity: 1; }

.modal__item {
  transform: scale(0.75);
  transition: opacity 320ms ease, transform 320ms ease;
  opacity: 0; }

.modal__caption {
  text-align: center;
  color: #ffffff;
  margin-top: 1rem;
  font-size: 0.875rem; }

.modal--loaded .modal__item {
  transform: scale(1);
  opacity: 1; }

.modal--loaded img.modal__item {
  max-height: 90vh; }

.modal__close {
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23ffffff%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2259%22%20height%3D%2259%22%20viewBox%3D%220%200%2059%2059%22%3E%3Cpath%20d%3D%22M59%207.8L51.2%200%2029.5%2021.7%207.8%200%200%207.8l21.7%2021.7L0%2051.2%207.8%2059l21.7-21.7L51.2%2059l7.8-7.8-21.7-21.7z%22%2F%3E%3C%2Fsvg%3E);
  width: 3rem;
  height: 3rem;
  background-position: center;
  background-size: 1rem 1rem;
  text-indent: -999rem;
  overflow: hidden;
  background-repeat: no-repeat; }

.modal__nav--icon {
  display: block;
  width: 2.5rem;
  color: #ffffff;
  padding: 0.5rem;
  transition: background 300ms ease, color 300ms ease; }

.nav__wrap {
  position: absolute;
  width: 100%;
  background-color: #702F8A; }
  .menu-active .nav__wrap {
    z-index: 1; }

.nav {
  position: relative;
  visibility: hidden;
  transform: translate(0, -100%);
  z-index: -1;
  overflow: hidden;
  max-height: 0;
  transition: transform 500ms ease, visibility 500ms ease, max-height 500ms ease; }
  .menu-active .nav {
    visibility: visible;
    transform: translate(0, 0);
    max-height: 100vh; }
  @media (min-width: 1025px) {
    .nav {
      visibility: visible;
      overflow: visible;
      transform: none;
      max-height: none;
      transition: none;
      z-index: 1; } }
  .nav .wrap, .nav .faculty__slider, .nav
  .faculty__slider--alt, .nav .figure__slider, .nav .footer__bottom__wrap, .nav .hero__wrap {
    padding-left: 0;
    padding-right: 0; }
    @media (min-width: 1025px) {
      .nav .wrap, .nav .faculty__slider, .nav
      .faculty__slider--alt, .nav .figure__slider, .nav .footer__bottom__wrap, .nav .hero__wrap {
        display: flex;
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem; }
        .header--collapsed .nav .wrap, .header--collapsed .nav .faculty__slider, .header--collapsed .nav
        .faculty__slider--alt, .header--collapsed .nav .figure__slider, .header--collapsed .nav .footer__bottom__wrap, .header--collapsed .nav .hero__wrap,
        .internal .nav .wrap,
        .internal .nav .faculty__slider,
        .internal .nav
        .faculty__slider--alt,
        .internal .nav .figure__slider,
        .internal .nav .footer__bottom__wrap,
        .internal .nav .hero__wrap {
          max-width: 90rem;
          padding-right: 0;
          padding-left: 0; } }
    @media (min-width: 72em) {
      .header--collapsed .nav .wrap, .header--collapsed .nav .faculty__slider, .header--collapsed .nav
      .faculty__slider--alt, .header--collapsed .nav .figure__slider, .header--collapsed .nav .footer__bottom__wrap, .header--collapsed .nav .hero__wrap,
      .internal .nav .wrap,
      .internal .nav .faculty__slider,
      .internal .nav
      .faculty__slider--alt,
      .internal .nav .figure__slider,
      .internal .nav .footer__bottom__wrap,
      .internal .nav .hero__wrap {
        padding-left: 1rem; } }

.nav__list {
  padding-left: 0;
  list-style: none;
  margin: 0;
  opacity: 0;
  transition: opacity 300ms ease 200ms; }
  .menu-active .nav__list {
    opacity: 1; }
  @media (min-width: 1025px) {
    .nav__list {
      opacity: 1;
      display: flex; }
      .no-touchevents .nav__list li:hover .nav__list--sub {
        position: absolute;
        left: 0;
        visibility: visible;
        max-height: 600px; }
      .nav__list li:hover:last-child .nav__list--sub {
        left: auto;
        right: 0; } }

.nav__item {
  padding: 0;
  margin-bottom: 0;
  border-top: 1px solid #9D56CC; }
  @media (min-width: 1025px) {
    .nav__item {
      display: table;
      position: relative;
      padding: 0;
      border-top: 0;
      border-left: 1px solid #9D56CC; }
      .no-touchevents .nav__item:hover {
        background-color: #9D56CC; }
        .no-touchevents .nav__item:hover .nav__link {
          text-decoration: underline; }
        .no-touchevents .nav__item:hover .nav__list--sub {
          position: absolute;
          left: 0;
          visibility: visible; }
        .no-touchevents .nav__item:hover .nav__link__toggle {
          pointer-events: none;
          transform: rotate(180deg); } }
  @media (min-width: 1025px) {
    .nav__item:last-child {
      border-right: 1px solid #9D56CC; } }

.nav__link {
  position: relative;
  display: inline-block;
  width: calc(100% - 2.5rem);
  color: #ffffff;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.9375rem;
  text-decoration: none;
  padding: 1rem 0 1rem 1rem;
  text-align: left; }
  @media (min-width: 1025px) {
    .nav__link {
      width: calc(100% - 2rem);
      white-space: normal;
      height: 76px;
      max-width: 220px; } }

.nav__link__toggle {
  display: inline-block;
  vertical-align: middle;
  width: 1.75rem;
  color: #ffffff;
  padding: 0.5rem; }
  @media (min-width: 1025px) {
    .nav__link__toggle {
      height: 76px;
      vertical-align: top; } }
  .nav__item--active .nav__link__toggle {
    transform: rotate(180deg); }

.nav__list--sub {
  position: absolute;
  left: -999rem;
  visibility: hidden;
  padding: 1rem 0;
  margin: 0; }
  @media (min-width: 1025px) {
    .nav__list--sub {
      background-color: #d8d6d6;
      width: 20rem;
      max-height: 0;
      overflow: hidden;
      transition: max-height 800ms; } }
  .nav__item--active .nav__list--sub {
    position: static;
    visibility: visible; }
    @media (min-width: 1025px) {
      .nav__item--active .nav__list--sub {
        position: absolute;
        left: 0;
        max-height: 600px; } }

.nav__item--sub {
  padding: 0.25rem 1rem;
  margin-bottom: 0; }

.nav__link--sub {
  display: inline-block;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.9375rem;
  color: #ffffff;
  padding: 0.25rem 1rem;
  text-decoration: none;
  transition: background 300ms ease, color 300ms ease; }
  @media (min-width: 1025px) {
    .nav__link--sub {
      display: block;
      color: #010101; } }
  .nav__link--sub:hover {
    background-color: #9D56CC;
    color: #ffffff; }

.nav__search {
  display: none; }
  @media (min-width: 1025px) {
    .nav__search {
      display: inline-block;
      vertical-align: middle;
      width: 76px; }
      .nav__search .tcon-search--xcross {
        width: 76px;
        height: 76px;
        margin: 0 auto; }
      .search-active .nav__search {
        background-color: #9D56CC; } }

.nav__logo {
  display: none;
  color: #ffffff;
  font-size: 1.3125rem;
  text-decoration: none;
  margin-left: 0;
  margin-right: auto; }
  @media (min-width: 80em) {
    .nav__logo {
      font-size: 2.25rem; } }
  .header--collapsed .nav__logo,
  .internal .nav__logo {
    display: none; }
    @media (min-width: 1025px) {
      .header--collapsed .nav__logo,
      .internal .nav__logo {
        display: block;
        white-space: nowrap;
        margin-right: 2rem;
        margin-left: 2rem; } }
    @media (min-width: 80em) {
      .header--collapsed .nav__logo,
      .internal .nav__logo {
        margin-right: 4rem; } }

.pagination {
  margin: 0;
  padding: 0;
  display: inline-block; }
  .pagination li {
    float: left;
    display: block;
    margin: 0; }
    @media (min-width: 30em) {
      .pagination li {
        margin: 0 0.1rem 0; } }

.pagination__link {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  background-color: #EFEFEF;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  font-size: 0.9375rem;
  color: #010101;
  transition: background-color 300ms ease, color 300ms ease;
  text-decoration: none; }
  .pagination__link:hover, .pagination__link:focus, .pagination__link.pagination__link--active {
    background-color: #236192;
    color: #ffffff; }

.pagination__icon, .pagination__icon--prev, .pagination__icon--next {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.6rem; }

@media (min-width: 30em) {
  .pagination__icon--prev {
    margin-right: 0.8rem; } }

@media (min-width: 30em) {
  .pagination__icon--next {
    margin-left: 0.8rem; } }

.pre-footer {
  padding: 3rem 0 2rem; }
  @media (min-width: 48em) {
    .pre-footer {
      padding: 5rem 0 4rem; } }
  @media (min-width: 64em) {
    .pre-footer {
      padding: 8rem 0 7rem; } }
  .pre-footer ul li {
    width: 90%;
    margin-bottom: 1rem; }
    @media (min-width: 48.063em) {
      .pre-footer ul li {
        width: auto; } }

.pre-footer__header {
  text-align: center;
  margin-bottom: 3rem; }

.pre-footer__header__logo {
  display: block;
  margin: 0 auto;
  max-width: 35rem;
  margin-bottom: 1rem; }
  @media (min-width: 48em) {
    .pre-footer__header__logo {
      width: 100%;
      margin-bottom: 0;
      margin-right: 0.8rem;
      display: inline-block;
      vertical-align: middle; } }

@media print {
  header, nav, .footer,
  .alert, .juicer, .sidebar,
  .calendar {
    display: none; }
  .ungrid .grid {
    display: block !important; }
  .ungrid .grid__item {
    float: none !important; }
  .container--purple-dark {
    color: #010101 !important; }
  * {
    margin-bottom: 0; }
  .main {
    margin: 0 !important; }
  .page {
    padding-top: 0 !important;
    overflow: hidden; }
  p a,
  .feed__item a,
  .image__item a,
  .event__content--link a {
    word-wrap: break-word;
    color: #010101; }
  .tabs__nav__link:after,
  .accordion__toggle:after,
  .tabs__main__toggle:after {
    content: ''; }
  .tabs__nav__item {
    display: none !important; }
  .tabs__main__content {
    position: static !important;
    visibility: visible !important; }
  .tabs__main__toggle {
    display: block !important; }
  .tabs__main__interior {
    padding: 1rem 0 0 !important;
    border-top: 0 !important; }
  .accordion__interior {
    border-top: 0 !important; }
  .accordion__content {
    position: static !important; }
  .accordion__interior {
    padding: 1rem 0 0 !important; }
  .block__img:before {
    background: transparent; }
  .block__text {
    text-shadow: none;
    color: #010101; }
  .block__heading {
    color: #010101;
    text-shadow: none; }
  .button__link:after,
  .subnav__link--horizontal:after {
    position: static !important;
    background-color: transparent !important; }
  .button__overlay {
    color: #010101;
    text-shadow: none; }
  .media__icon, .play__icon, .circle__arrow,
  .alert__close, .story__hero__arrow, .hero__arrow {
    display: none; }
  .appear-bottom {
    opacity: 1; }
  .animateInHome {
    transform: translateX(0); }
  .main {
    width: 100% !important; }
  .slick-arrow,
  .figure__slider,
  .figure__slider--alt,
  .slick-slide img,
  img {
    display: none !important; }
  .responsive-table thead {
    padding: 0 !important;
    border: 0 !important;
    position: relative !important;
    clip: auto !important;
    height: auto !important;
    width: auto !important;
    overflow: auto !important; }
  .responsive-table tr:nth-of-type(odd) {
    background: #EFEFEF !important;
    display: table-row !important; }
  .responsive-table th,
  .responsive-table td {
    padding: .5em;
    vertical-align: middle;
    display: table-cell !important; }
  .responsive-table tbody {
    display: table-row-group !important; }
    .responsive-table tbody tr {
      display: table-row !important;
      border-width: 1px !important; }
    .responsive-table tbody th[scope="row"] {
      background-color: transparent !important;
      color: #010101 !important;
      text-align: left !important; }
    .responsive-table tbody td {
      text-align: center !important; }
      .responsive-table tbody td:nth-of-type(odd) {
        background-color: transparent !important; }
    .responsive-table tbody td[data-title]:before {
      content: none !important; } }

.search__wrap {
  position: absolute;
  top: 72px;
  width: 100%;
  background-color: #EFEFEF; }
  @media (min-width: 64em) {
    .search__wrap {
      top: 77px; } }

.search {
  visibility: hidden;
  transform: translate(0, -100%);
  z-index: -1;
  overflow: hidden;
  max-height: 0;
  transition: transform 500ms ease, visibility 500ms ease, max-height 500ms ease; }
  .search-active .search {
    z-index: 0;
    visibility: visible;
    transform: translate(0, 0);
    max-height: 156px; }

.search__form {
  position: relative;
  padding: 3.5rem 0 2rem; }
  .search__form .form__radio {
    padding-left: 2rem;
    font-size: 0.9375rem;
    font-weight: bold; }
    .search__form .form__radio::before {
      background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%239D56CC%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M20%200C8.96%200%200%208.96%200%2020s8.96%2020%2020%2020%2020-8.96%2020-20S31.04%200%2020%200zm0%2036c-8.84%200-16-7.16-16-16S11.16%204%2020%204s16%207.16%2016%2016-7.16%2016-16%2016z%22%2F%3E%3C%2Fsvg%3E); }
  .search__form .form__radio[aria-checked='true']:before {
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%239D56CC%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M20%2010c-5.52%200-10%204.48-10%2010s4.48%2010%2010%2010%2010-4.48%2010-10-4.48-10-10-10zm0-10C8.96%200%200%208.96%200%2020s8.96%2020%2020%2020%2020-8.96%2020-20S31.04%200%2020%200zm0%2036c-8.84%200-16-7.16-16-16S11.16%204%2020%204s16%207.16%2016%2016-7.16%2016-16%2016z%22%2F%3E%3C%2Fsvg%3E); }
  .search__form .form__inline__group:last-child {
    margin-left: 1rem; }
  .search__form fieldset {
    border: 0;
    margin: 0;
    padding: 0; }
  .search__form legend {
    opacity: 0;
    font-size: 0; }

.search__input {
  border-bottom: 2px solid #9D56CC;
  width: 100%;
  border-radius: 0;
  padding-bottom: 0.5rem;
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  color: #626362;
  padding-right: 2rem; }
  @media screen and (min-width: 320px) {
    .search__input {
      font-size: calc(18px + 6 * (100vw - 320px) / 1120); } }
  @media screen and (min-width: 1440px) {
    .search__input {
      font-size: 24px; } }

.search__button {
  position: absolute;
  right: 0;
  top: 4rem; }

.search__button--icon {
  display: block;
  color: #702F8A;
  width: 2rem;
  padding: 2px 6px 3px; }
  .search__button--icon svg {
    overflow: visible; }

.gsc-selected-option-container {
  width: inherit !important;
  max-width: none !important; }

.cse .gsc-control-cse, .gsc-control-cse {
  padding-left: 0 !important; }

.gsc-table-result, .gsc-thumbnail-inside, .gsc-url-top {
  padding-left: 0 !important; }

.gsc-result-info {
  padding-left: 0 !important; }

.sidebar__panel {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }
  @media (min-width: 48em) {
    .sidebar__panel {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem; } }
  .sidebar__panel .h5, .sidebar__panel .event--vertical .event__content--link, .event--vertical .sidebar__panel .event__content--link, .sidebar__panel .faculty__slide .faculty__content--name, .faculty__slide .sidebar__panel .faculty__content--name, .sidebar__panel .feed__heading {
    margin-top: 1rem; }

.sticky {
  position: fixed; }

.subnav__list--horizontal {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center; }

.subnav__item--horizontal {
  display: inline-block;
  margin: 0 1rem;
  overflow: hidden;
  padding-bottom: 5px; }

.subnav__link--horizontal {
  color: #702F8A;
  font-size: 1.0625rem;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  transition: color 300ms ease; }
  .subnav__link--horizontal:after {
    content: '';
    transition: all 300ms ease;
    backface-visibility: hidden;
    position: absolute;
    bottom: -5px;
    height: 2px;
    width: 100%;
    background: #9D56CC;
    left: 50%;
    transform: translateX(-50%) scaleX(0); }
  .subnav__link--horizontal:hover, .subnav__link--horizontal:focus {
    color: #9D56CC; }
    .subnav__link--horizontal:hover:after, .subnav__link--horizontal:focus:after {
      transform: translateX(-50%) scaleX(1); }

.subnav__toggle {
  padding: 0.6rem 0;
  border-bottom: 0.3rem solid #702F8A;
  width: 100%;
  text-align: left;
  display: flex;
  outline: 0; }
  @media (min-width: 64em) {
    .subnav__toggle {
      cursor: default; } }

.subnav__heading {
  font-size: 0.9375rem;
  text-transform: uppercase;
  width: calc(100% - 1rem);
  font-family: "Raleway", sans-serif; }

.subnav__dropdown {
  display: inline-block;
  width: 1rem;
  height: 0.5rem;
  margin-top: 0.35rem;
  transition: transform 300ms ease; }
  .subnav--active .subnav__dropdown {
    transform: rotate(-180deg); }
  @media (min-width: 64em) {
    .subnav__dropdown {
      display: none; } }

.subnav__list {
  position: absolute;
  left: -999rem;
  visibility: hidden; }
  @media (min-width: 64em) {
    .subnav__list {
      position: static;
      left: auto;
      visibility: visible; } }
  .subnav--active .subnav__list {
    position: static;
    left: auto;
    visibility: visible; }

.subnav__list,
.subnav__list--nested {
  margin: 0;
  padding: 0;
  list-style: none; }

.subnav__list--nested {
  margin-bottom: 1rem; }

.subnav__item,
.subnav__item--nested {
  font-size: 0.9375rem;
  margin-bottom: 0; }

.subnav__item {
  border-bottom: 0.1rem solid #A0D1CA; }

.subnav__link,
.subnav__link--nested {
  text-decoration: none;
  color: #010101;
  display: block; }

.subnav__link {
  font-weight: 700;
  padding: 0.8rem; }
  .subnav__link:hover, .subnav__link:focus {
    background-color: #9D56CC;
    color: #ffffff; }

.subnav__link--nested {
  padding: 0.5rem 0.5rem 0.5rem 1.5rem; }
  .subnav__link--nested:hover, .subnav__link--nested:focus {
    color: #9D56CC; }

.subnav__link--current {
  color: #9D56CC; }

.table, .table--responsive {
  margin-bottom: 3rem;
  border-bottom: 0.2rem solid #012169; }
  .table table, .table--responsive table {
    width: calc(100% + 0.25rem);
    margin-right: -0.125rem;
    margin-left: -0.125rem;
    border-collapse: separate;
    border-spacing: 0.125rem 0; }
    .table table td, .table--responsive table td {
      font-size: 0.875rem; }
  .table tr:nth-of-type(odd), .table--responsive tr:nth-of-type(odd) {
    background: #EFEFEF; }
  .table th, .table--responsive th,
  .table td, .table--responsive td {
    padding: 0.625rem 1.25rem;
    text-align: left;
    vertical-align: top; }
  .table th.text-right, .table--responsive th.text-right,
  .table td.text-right, .table--responsive td.text-right {
    text-align: right; }
  .table th, .table--responsive th {
    background: #012169;
    color: white;
    font-weight: normal; }

.table__shadow {
  position: relative; }
  .table__shadow::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 0.375rem;
    height: 100%;
    background-image: linear-gradient(to right, transparent, rgba(83, 86, 90, 0.5));
    content: '';
    pointer-events: none; }
    @media (min-width: 48em) {
      .table__shadow::before {
        display: none; } }

.table__scroll {
  overflow: auto; }
  .table__scroll table {
    min-width: 64rem; }

.responsive-table thead {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden; }
  @media (min-width: 48em) {
    .responsive-table thead {
      position: relative;
      clip: auto;
      height: auto;
      width: auto;
      overflow: auto; } }
  .responsive-table thead th:first-of-type {
    text-align: left; }

.responsive-table tbody,
.responsive-table tr,
.responsive-table th,
.responsive-table td {
  display: block;
  padding: 0;
  text-align: left;
  white-space: normal; }

.responsive-table tr:nth-of-type(odd) {
  background: transparent; }

@media (min-width: 48em) {
  .responsive-table tr {
    background: #EFEFEF;
    display: table-row; } }

.responsive-table th,
.responsive-table td {
  padding: .5em;
  vertical-align: middle; }
  @media (min-width: 30em) {
    .responsive-table th,
    .responsive-table td {
      padding: .75em .5em; } }
  @media (min-width: 48em) {
    .responsive-table th,
    .responsive-table td {
      display: table-cell; } }
  @media (min-width: 62em) {
    .responsive-table th,
    .responsive-table td {
      padding: .75em .5em; } }
  @media (min-width: 75em) {
    .responsive-table th,
    .responsive-table td {
      padding: .75em; } }

@media (min-width: 48em) {
  .responsive-table tbody {
    display: table-row-group; } }

.responsive-table tbody tr {
  margin-bottom: 1em; }
  @media (min-width: 48em) {
    .responsive-table tbody tr {
      display: table-row;
      border-width: 1px; } }
  .responsive-table tbody tr:last-of-type {
    margin-bottom: 0; }

.responsive-table tbody th[scope="row"] {
  background-color: #012169;
  color: white; }
  @media (min-width: 48em) {
    .responsive-table tbody th[scope="row"] {
      background-color: transparent;
      color: #010101;
      text-align: left; } }

.responsive-table tbody td:nth-of-type(odd) {
  background: #EFEFEF; }

@media (min-width: 48em) {
  .responsive-table tbody td:nth-of-type(odd) {
    background-color: transparent; } }

.responsive-table tbody td[data-title]:before {
  content: attr(data-title) " - ";
  float: left;
  color: #010101; }
  @media (min-width: 48em) {
    .responsive-table tbody td[data-title]:before {
      content: none; } }

.tabs {
  margin-bottom: 2rem; }

.tabs__nav {
  position: relative;
  display: none;
  margin-bottom: -0.19rem;
  list-style: none; }
  .tabs__nav::before, .tabs__nav::after {
    content: '';
    display: table; }
  .tabs__nav::after {
    clear: both; }
  @media (min-width: 48em) {
    .tabs__nav {
      display: table; } }

.tabs__nav__item {
  display: table-cell;
  vertical-align: bottom;
  margin-bottom: 0;
  padding: 0 0.1rem; }

.tabs__nav__link {
  display: block;
  padding: 0.5rem 1rem;
  border-bottom: 0.2rem solid #45105B;
  color: #ffffff;
  text-decoration: none;
  font-weight: 700;
  background: #702F8A;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.0625rem; }
  .tabs__nav__item--active .tabs__nav__link {
    border-color: #45105B;
    background: #45105B; }

@media (min-width: 48em) {
  .tabs__main {
    border-top: 0.2rem solid #45105B; } }

@media (min-width: 48em) {
  .tabs__main__item {
    border-bottom: 0;
    margin: 0; } }

@media (min-width: 48em) {
  .tabs__main__toggle {
    display: none; } }

.tabs__main__icon__open {
  display: block; }
  .tabs__main__item--active--accordion .tabs__main__icon__open {
    transform: rotate(90deg); }

.tabs__main__content {
  position: absolute;
  left: -999rem;
  visibility: hidden;
  top: 0; }
  .tabs__main__item--active--accordion .tabs__main__content {
    visibility: visible;
    position: static; }
    @media (min-width: 48em) {
      .tabs__main__item--active--accordion .tabs__main__content {
        position: absolute; } }
  .tabs__main__item--active .tabs__main__content {
    visibility: visible; }
    @media (min-width: 48em) {
      .tabs__main__item--active .tabs__main__content {
        position: static; } }

.tabs__main__interior {
  margin: 0;
  padding: 2.5rem 0;
  border: 0;
  border-top: 0; }
  @media (min-width: 48em) {
    .tabs__main__interior {
      padding: 3rem 0; } }

.widget {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  .widget::before, .widget::after {
    content: '';
    display: table; }
  .widget::after {
    clear: both; }

.widget--border {
  padding-top: 4rem;
  padding-bottom: 4rem;
  border-top: 1px solid #A0D1CA;
  border-bottom: 1px solid #A0D1CA; }

.widget__img {
  margin-bottom: 1rem; }
  @media (min-width: 48em) {
    .widget__img {
      float: left;
      margin-right: 2rem;
      width: 45%;
      margin-bottom: 0; } }

.widget__content {
  overflow: hidden; }
  .widget__content .button__solid {
    white-space: inherit; }

.widget__content--title {
  margin-top: 2rem; }
  @media (min-width: 48em) {
    .widget__content--title {
      margin-top: 0; } }
