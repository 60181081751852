$baseline: 1.56;
$minScreen: 320px;
$maxScreen: 1440px;

body {
  font-family: $sans-serif;
  @include font-size(18);
  line-height: $baseline;
  color: $black;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin: 0 0 1rem 0;
}


h1,
.h1 {
  font-weight: $light;
  font-size: 2.2rem;
  color: $maroon;
  line-height: 1.148;
  @include mq(32em) {
    @include font-size(46);
  }
  @include mq(48em) {
    @include font-size(50);
  }
}

h2,
.h2 {
  font-weight: $light;
  font-size: 1.875rem;
  color: $maroon;
  line-height: 1.26;
  margin-bottom: 0.5rem;
  @include mq(32em) {
    @include font-size(38);
  }
}



h3,
.h3 {
  @include font-size(32);
  line-height: 1.18;
  font-weight: $reg;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
}

h4,
.h4 {
  @include font-size(24);
  line-height: 1.25;
  font-weight: $reg;
  margin-top: 1rem;
  margin-bottom: 2rem;
  color: $black;
  .tabs &,
  .accordion & {
    margin-top: 0;
  }
}

.h4, h4 + .event__divider {
  margin-bottom: 1rem;
}

h5,
.h5 {
  @include font-size(21);
  font-weight: $reg;
  line-height: 1.33;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
}

h6,
.h6 {
  @include font-size(17);
  font-weight: $bold;
  line-height: 1.29;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
}

.clear-heading {
  margin-top: 0;
}

a:not([class]),
a[class=""],
a[class="ally-focus-within"] {
  color: $purple;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.anchor {
  color: $purple;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

ul,
ol {
  @include spacing;
  padding-left: 1.25rem;
}

li {
  margin-bottom: 0.75rem;
}


p {
  margin: 0 0 1.111rem 0;
}

.p--small {
  @include font-size(15);
  line-height: 1.46;
}

.p--intro {
  font-weight: $light;
  @include font-size(24);
  line-height: 1.41;
}

.p--intro-program {
  @extend .p--intro;
  color: $blue;
}


.ul--plain {
  @include clean;
  list-style: none;
}

.ul--plain-no-space {
  @include clean;
  list-style: none;
  li {
    margin-bottom: 0;
  }
}

.ul--inline {
  li {
    display: inline-block;
    margin: 0.25rem 1rem;
  }
}

hr {
  @include spacing;
  border: 0;
  height: 1px;
  background-color: $blue-dark;
}

.hr--gray {
  background-color: $gray;
}

.hr--green-dark {
  background-color: $green-dark;
}

.hr--green-dark-alt {
  @include mq($max: 48em) {
    margin-top: -2.5rem;
    margin-bottom: -1.5rem;
  }
  background-color: $green-dark;
}

.hr--purple {
  background-color: $purple;
}

.hr--purple {
  background-color: $purple-dark;
}

.tag {
  color: $gray;
  @include font-size(15);
  text-transform: uppercase;
  font-weight: $bold;
  line-height: ($baseline * 1.5);
  margin-left: 5px;
}

.fadeIn {
  animation-name: fadeIn;
}

.animate {
  visibility: hidden;
}

.animated {
  visibility: visible !important;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animate-text {
  @include mq(48em) {
    visibility: hidden;
  }
}

.animated-text {
  @include mq(48em) {
    visibility: visible !important;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
}
