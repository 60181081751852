$modal-overlay-background-color: #000 !default;
$modal-overlay-background-alpha: 0.75 !default;
$modal-overlay-background: rgba($modal-overlay-background-color, $modal-overlay-background-alpha);
$modal-video-background: #000 !default;

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.modal__overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	background: $modal-overlay-background;
}

.modal__container {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.modal__content {

}

.modal__status {
	text-align: center;
}

.modal__item {
	display: block;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
}

.modal__video {
	position: relative;
	padding-bottom: 56.25%;
	background: $modal-video-background;
}

.modal__audio {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.modal__embed {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 0;
}

.modal__controls {

}

.modal__nav {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.modal__nav--previous {
	left: 0;
}

.modal__nav--next {
	right: 0;
}

.modal__close {
	position: absolute;
	top: 0;
	right: 0;
}

// customizations

.modal {
	z-index: 1002;
}

.modal__container {
	max-width: 90rem;
	margin: auto;
	padding: 3rem 1rem;
	@include mq(48em) {
		padding: 3rem 2rem;
	}
	@include mq(80em) {
		padding: 3rem;
	}
}

.modal__overlay,
.modal__status,
.modal__controls,
.modal__close {
	transition: opacity 320ms ease;
	opacity: 0;
}

.modal--active .modal__overlay,
.modal--active .modal__controls,
.modal--active .modal__close,
.modal--loading .modal__status,
.modal--loaded .modal__status {
	opacity: 1;
}

.modal__item {
	transform: scale(0.75);
	transition: opacity 320ms ease, transform 320ms ease;
	opacity: 0;
}

.modal__caption {
  text-align: center;
  color: $white;
  margin-top: 1rem;
  @include font-size(14);
}

.modal--loaded .modal__item {
	transform: scale(1);
	opacity: 1;
}

.modal--loaded img.modal__item {
	max-height: 90vh;
}

.modal__close {
	@include fill($svgstore--close, $white);
	width: 3rem;
	height: 3rem;
	background-position: center;
	background-size: 1rem 1rem;
	text-indent: -999rem;
	overflow: hidden;
	background-repeat: no-repeat;
}

.modal__nav--icon {
	display: block;
	width: 2.5rem;
	color: $white;
	padding: 0.5rem;
	transition:
		background $transition,
		color $transition;
	&:hover {

	}
}
