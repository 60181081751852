.page {
  padding-top: $line-height-short;
  overflow-x: hidden;
  @include mq(64em) {
    padding-top: $line-height-tall;
  }
  .search-active &,
  .menu-active & {
    @include mq($max: 64em) {
      overflow-y: hidden;
      top: 0; right: 0; bottom: 0; left: 0;
    }
  }
}

.wrap {
  @include clear;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;

  @include mq(48em) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @include mq(90em) {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

.wrap > .wrap {
  @include mq($max: 30em){
    padding-left: 0;
    padding-right: 0;
  }
}

.wrap--alt {
  @include clear;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.wrap--wide {
  max-width: 96rem;
}

.wrap--extra-wide {
  max-width: 96rem;
  @include mq(48em) {
    padding-left: 0;
    padding-right: 0;
  }
}

.wrap--narrow {
  max-width: 70rem;
}

.wrap--slim {
  max-width: 58rem;
  margin: 0 auto;
}

.wrap--relative {
  position: relative;
}

.wrap--mobile-padding {
  @include mq($max: 30em){
    margin-right: auto;
    margin-left: auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.main,
.sidebar,
.main--full {
  @include spacing(4);
  @include mq(64em) {
    @include spacing(4);
  }
}
.main--full + div {
  margin-top: -5rem;
}

.main--breadcrumb {
  margin-top: 4rem;
  margin-bottom: -3rem;
  @include mq(64em) {
    margin-bottom: -5rem;
    width: calc(100% - 22rem);
    float: right;
  }
  @include mq(90em) {
    width: calc(100% - 24rem);
  }
}

.main {
  @include spacing(2);
  @include mq(64em) {
    @include spacing(4);
    width: calc(100% - 22rem);
    float: right;
  }

  @include mq(90em) {
    width: calc(100% - 24rem);
  }
}

.main--center {
  @include mq(64em) {
    margin-right: auto;
    margin-left: auto;
    float: none;
  }
}

.sidebar {
  @include mq(64em) {
    width: 18rem;
    float: left;
    clear: left;
  }
}

.main + .sidebar + .main {
  @include mq(64em) {
    margin-top: 0;
  }
}

.sidebar + .main + .sidebar {
  margin: 0;
  @include mq(64em) {
    margin-top: -2.5rem;
  }
}

.section {
  @include spacing(2);
}

.section--4 {
  @include spacing(4);
}

.section--4--mobile {
  @include spacing(2);
  @include mq(48em) {
    @include spacing(4);
  }
}

.section--5 {
  @include spacing(5);
}

.section-padding--4 {
  padding: 4.25rem 0;
}

.section-padding__top--4 {
  padding-top: 4.25rem;
}

.section__top--4 {
  margin-top: 4.25rem;
}

.section__bottom--4 {
  margin-bottom: 4.25rem;
}

.container--gray {
  background-color: $gray;
}

.container--gray-lighter {
  background-color: $gray-lighter;
}

.container--red {
  background-color: red;
}

.container--purple-dark {
  background-color: $purple-dark;
  color: $white;
}

.container--maroon {
  background-color: $maroon;
  color: $white;
}
