.table {
  margin-bottom: 3rem;
  border-bottom: 0.2rem solid $blue-dark;

  table {
    width: calc(100% + 0.25rem);
    margin-right: -0.125rem;
    margin-left: -0.125rem;
    border-collapse: separate;
    border-spacing: 0.125rem 0;
    // table-layout: fixed;
    td {
      @include font-size(14);

    }
  }
  tr:nth-of-type(odd) {
    background: $gray-lighter;
  }

  th,
  td {
    padding: 0.625rem 1.25rem;
    text-align: left;
    vertical-align: top;
  }
  th.text-right,
  td.text-right {
    text-align: right
  }

  th {
    background: $blue-dark;
    color: white;
    font-weight: normal;
  }
}

.table__shadow {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 0.375rem;
    height: 100%;
    background-image: linear-gradient(to right, transparent, $gray-opacity);
    content: '';
    pointer-events: none;
    @include mq(48em) {
      display: none;
    }
  }
}

.table__scroll {
  overflow: auto;

  table {
    min-width: 64rem;
  }
}


.table--responsive {
  @extend .table;
}

.responsive-table {
  thead {
    // Accessibly hide <thead> on narrow viewports
    position: absolute;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;

    @media (min-width: 48em) {
      // Unhide <thead> on wide viewports
      position: relative;
      clip: auto;
      height: auto;
      width: auto;
      overflow: auto;
    }

    th {
      &:first-of-type {
        text-align: left;
      }
    }
  }

  // Set these items to display: block for narrow viewports
  tbody,
  tr,
  th,
  td {
    display: block;
    padding: 0;
    text-align: left;
    white-space: normal;
  }

  tr {
    &:nth-of-type(odd) {
      background: transparent;
    }
    @media (min-width: 48em) {
      background: $gray-lighter;
      display: table-row;
    }
  }

  th,
  td {
    padding: .5em;
    vertical-align: middle;

    @media (min-width: 30em) {
      padding: .75em .5em;
    }

    @media (min-width: 48em) {
      // Undo display: block
      display: table-cell;

    }

    @media (min-width: 62em) {
      padding: .75em .5em;
    }

    @media (min-width: 75em) {
      padding: .75em;
    }
  }

  tbody {
    @media (min-width: 48em) {
      // Undo display: block
      display: table-row-group;
    }

    tr {
      margin-bottom: 1em;

      @media (min-width: 48em) {
        // Undo display: block
        display: table-row;
        border-width: 1px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    th[scope="row"] {
      background-color: $blue-dark;
      color: white;

      @media (min-width: 48em) {
        background-color: transparent;
        color: $black;
        text-align: left;
      }
    }

    td {
      // text-align: right;
      &:nth-of-type(odd) {
        background: $gray-lighter;
      }

      @media (min-width: 48em) {
        // text-align: center;
        &:nth-of-type(odd) {
          background-color: transparent;
        }
      }
    }

    td[data-type=currency] {
      // text-align: right;
    }

    td[data-title]:before {
      content: attr(data-title) "\00a0-\00a0";
      float: left;
      color: $black;

      @media (min-width: 48em) {
        content: none;
      }
    }
  }
}
