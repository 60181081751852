.image__item {
  padding-bottom: 2rem;
  @include mq(48em) {
    padding-bottom: 4rem;
  }
  a {
    display: block;
  }
  p {
    a {
      display: inline;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  img {
    margin-bottom: 1.8rem;
  }
  a:hover {
    text-decoration: none;

    .image__heading__text {
      -webkit-backface-visibility:hidden;
      backface-visibility:hidden;
      transition: color $transition;
      text-decoration: none;
      &:after {
        transform: translateX(-50%) scaleX(1);
      }
    }
  }
}

.image__caption {
  @extend .p--small;
  padding: 0.5rem 0;
  border-bottom: 0.0625rem solid $blue;
}

.image__heading {
  margin: 0 0 0.5rem;
  overflow: hidden;
  padding-bottom: 5px;
  display: inline-block;
}

.image__heading__text {
  color: $purple;
  padding: 0;
  position: relative;
  margin-bottom: 5px;
  &:after {
    content: '';
    transition: all $transition;
    backface-visibility: hidden;
    position: absolute;
    bottom: -5px;
    height: 2px;
    width: 100%;
    background: $purple;
    left: 50%;
    transform: translateX(-50%) scaleX(0);
  }
}

.image__caption__heading {
  @include font-size(15);
  font-weight: $bold;
  margin: 0.8rem 0 0.5rem;
}
