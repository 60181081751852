.search__wrap {
  position: absolute;
  top: 72px;
  width: 100%;
  background-color: $gray-lighter;
  @include mq(64em) {
    top: 77px;
  }
}

.search {
  visibility: hidden;
  transform: translate(0,-100%);
  z-index: -1;
  overflow: hidden;
  max-height: 0;
  transition:
  transform $header-transition,
  visibility $header-transition,
  max-height $header-transition;
  .search-active & {
    z-index: 0;
    visibility: visible;
    transform: translate(0,0);
    max-height: 156px;
  }
}

.search__form {
  position: relative;
  padding: 3.5rem 0 2rem;
  .form__radio {
    padding-left: 2rem;
    @include font-size(15);
    font-weight: bold;
    &::before {
      @include fill($svgstore--radial_button_unchecked, $purple);
    }
  }

  .form__radio[aria-checked='true'] {
    &:before {
      @include fill($svgstore--radial_button_checked, $purple);
    }
  }

  .form__inline__group {
    &:last-child {
      margin-left: 1rem;
    }
  }

  fieldset {
    border: 0;
    @include clean;
  }

  legend {
    opacity: 0;
    font-size: 0;
  }
}

.search__input {
  border-bottom: 2px solid $purple;
  width: 100%;
  border-radius: 0;
  padding-bottom: 0.5rem;
  font-family: $sans-serif;
  @include fluid-type(font-size, $minScreen, $maxScreen, 18px, 24px);
  color: #626362;
  padding-right: 2rem;
}

.search__button {
  position: absolute;
  right: 0;
  top: 4rem;
}

.search__button--icon {
  display: block;
  color: $purple-dark;
  width: 2rem;
  padding: 2px 6px 3px;
  svg {
    overflow: visible;
  }
}

.gsc-selected-option-container{
    width: inherit !important;
    max-width: none !important;
}

.cse .gsc-control-cse, .gsc-control-cse {
  padding-left: 0 !important;
}

.gsc-table-result, .gsc-thumbnail-inside, .gsc-url-top{
  padding-left: 0 !important;
}

.gsc-result-info{
  padding-left: 0 !important;
}