.home__heading {
  @include fluid-type(font-size, $minScreen, $maxScreen, 30px, 58px);
  color: $maroon;
}

.home__heading__section {
  padding-top: 2rem;
  @include mq(48em) {
    padding-top: 5rem;
  }

  p {
    @include mq(64em) {
      width: 50%;
    }
  }
}

.animateInHome {
  transform: translateX(-8rem);
  transition: transform 300ms ease-in-out;
}

.animatedInHome {
  transform: none;
}

@include mq($max: 48em) {
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20%);
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translateY(35%);
      opacity: 1;
    }
  }
}

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(0%);
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translateY(25%);
      opacity: 1;
    }
  }





.home__hero__arrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  opacity: 0;
  z-index: 1;
  animation: fadeInDown 1.5s ease forwards;
}

.home__hero__arrow--icon {
  @include fill($svgstore--hero_arrow, $blue-dark);
  display: block;
  width: 1rem;
  height: 2.5rem;
  background-size: auto 100%;
  margin-left: -10px;
  animation: fadeInDown 1.5s ease forwards;
  @include mq(48em) {
    @include fill($svgstore--home_arrow, $blue-dark);
    width: 1.5rem;
    height: 7.5rem;
  }
}
