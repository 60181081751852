.figure {
  // margin-bottom: 3rem;
}

.figure__donut {
  position: relative;
  height: 12rem;

  // margin-bottom: 3rem;
  @include mq(49em) {

  }
  circle {
    fill: none;
  }
}

.figure__circle {
  position: absolute;
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  z-index: -1;
  @extend .middle__center;
  transform: translateX(-50%);
}

.figure__circle--wrap {
  text-align: center;
  height: 12rem;
}

.svgdonut {
  display: block;
  margin: 0 auto;
  width: 170px;
  height: 100%;
}


.middle__center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.middle__circle {
  @extend .middle__center;
  border-radius: 50%;
  height: 12rem;
  width: 12rem;
}



.circle-back {
  stroke: $green;
  stroke-width: 6px;
}

.circle-front {
  stroke: $orange;
  stroke-width: 6px;

  /* 2 * Math.PI[3.14159] * radius + 1  */
  stroke-dasharray: 503px;
  stroke-dashoffset: 503px;
  transition: stroke-dashoffset .5s ease;
}


.figure__count__number--wrap {
  text-align: center;
  padding: 0;
}

.figure__count--number,
.figure__count--number-alt {
  font-weight: $light;
  color: $gray;
}

.figure__count--number {
  @include font-size(60);
}

.figure__count--number-alt {
  @include font-size(100);
  line-height: 1.2;
  small {
    display: block;

    @include mq(48em) {
      font-size: 50%;
      margin-top: -2.5rem;
    }

  }
  @include fluid-type(font-size, $minScreen, $maxScreen, 60px, 100px);
}

.figure__count--small {
  font-size: 25%;
  line-height: 1;
  margin-top: 1rem;
}

.figure__count--text {
  @include font-size(28);
  color: $gray;
  line-height: 1.14;
  margin-bottom: 0.5rem;
}

.figure__count--subtext {
  @include font-size(15);
}

[class^="figure__icon"] {
  display: block;
  width: 14rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  img {
    max-height: 10rem;
    margin: 0 auto;
  }
}


.figure__icon--teal {
  color: $teal;
}

.figure__icon--maroon {
  color: $maroon;
}

.figure__icon--blue-alt {
  color: $blue-alt;
}

.figure__icon--orange {
  color: $orange;
}


// figure slider

.figure__slider {
  @extend .wrap;
  padding: 2rem 0 0;
  @include mq(48em) {
    padding: 8rem 0;
  }
  min-height: 21rem;
}

.figure__slider--alt, .figure__slider,
.figure__slider--alt .slick-track,
.figure__slider .slick-track {
  min-height: 21rem;
}



.figure__slide__item {
  padding: 0 3rem;
  margin: 0.25rem;
}

.slider__nav--figure {
  .slick-disabled {
    opacity: .3;
    pointer-events: none;
    &:hover {
      background-color: $yellow;
      color: $black;
      cursor: default;
    }
  }
}



.lbl-sm {
  color: $blue;
  @include font-size(15);
  line-height: 1;
  margin-top: 0.5rem;
}

.green {
  background-color: $green;
}

