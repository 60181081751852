.feed__heading {
  @extend .h5;
  margin-top: 1rem;
}


.feed__item {
  a {
    display: block;
  }
}
