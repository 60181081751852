.dropdown {
  position: relative;
  margin-bottom: 2rem;

}

.dropdown--alt {
  display: inline-block;
  min-width: 20rem;
  margin-bottom: 0;
}


.dropdown__toggle {
  padding: 1rem 3.5rem 1rem 1rem;
  display: block;
  @include font-size(17);
  font-weight: $bold;
  text-decoration: none;
  .dropdown--alt & {
    color: $white;
    background: $blue-dark;
    transition: background $transition;
    &:hover {
      background: $blue;
    }
  }
  .dropdown--active & {
    background: $blue;
  }
}



// .dropdown--active {
//   background: $blue;
//   .sidebar & {
//     background: transparent;
//   }
// }

.dropdown__icon {
  position: absolute;
  display: block;
  width: 2rem;
  color: $white;
  right: 1rem;
  top: 1.25rem;
  padding: 0.25rem;
  transition: transform 300ms;
  .dropdown--active & {
    transform: rotate(180deg);
  }
  .dropdown--subnav & {
    @include mq(64em) {
      display: none;
    }
  }
}

.dropdown__list {
  @include hide;
  width: 100%;
  list-style: none;
  z-index: 1;
  padding-left: 0;
  margin: 0;
  background: $blue;
  .dropdown--alt & {
    background: $gray-lighter;
  }
  .dropdown--active & {
    left: 0;
  }
  .dropdown--subnav & {
    background: $white;
    @include mq(64em) {
      @include show;
    }
  }
}

.dropdown__item {
  margin: 0;
  &:last-child {
    border-bottom: 0;
  }
}

.dropdown__link {
  display: block;
  padding: 1rem;
  color: $black;
  @include font-size(15);
  font-weight: $bold;
  text-decoration: none;
  &:hover {
    color: $white;
    background: $blue-dark;
  }
}

.dropdown__toggle {
  display: block;
  color: $black;

}

