.wf-loading * {
	opacity: 0;
}

* {
  box-sizing: border-box;
}

:root {
	overflow-x: hidden;
	word-wrap: break-word;
}

button {
	cursor: pointer;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

audio::-internal-media-controls-download-button {
  display:none;
}

audio::-webkit-media-controls-enclosure {
  overflow:hidden;
}

audio::-webkit-media-controls-panel {
  width: calc(100% + 30px); /* Adjust as needed */
}

figure {
  margin: 0;
}

strong {
  font-weight: $bold;
}
