.footer {
  color: $white;
  @include smooth-font;
  overflow: hidden;
  a {
    color: $white;
    text-decoration: none;
  }
}

.footer__main {
  background: $purple-dark;
  padding: 5rem 1rem;
  overflow: hidden;
  @include mq(48em) {
    .grid {

    }
    .grid__item--4 {
      padding-top: 0;
    }
  }
}

.footer__logo {
  display: inline-block;
  @include font-size(40);
  line-height: 1;
  margin-bottom: 1rem;
}

.footer__bottom__logo {
  display: block;
  width: 6rem;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  @include mq(64em) {
    margin: 0 5rem 0 1rem;
  }
  @include mq(80em) {
    margin: 0 5rem 0 0;
  }
}

.footer__address {
  font-style: normal;
  @include font-size(16);
  margin-bottom: 2rem;
}


.footer__main__link__list,
.footer__link__list {
  @include clean;
  list-style: none;
}

.footer__main__link__list {
  @include mq(80em) {
    padding-right: 2rem;
  }
}

.footer__main__link__item {
  margin-bottom: 1.25rem;
}

.footer__link__list {
  float: left;
  &:first-child {
    margin-right: 0.8rem;
    @include mq(32em) {
      margin-right: 1.5rem;
    }

    @include mq(64em) {
      margin-right: 0;
    }
  }
  &:last-child {
  }
  @include mq(64em) {
    float: none;
  }
}

.footer__main__link {
  @include font-size(24)
  line-height: 1;
}

.footer__main__link {
  &:hover {
    @include underline;
  }
}
.footer__link {
  &:hover {
    text-decoration: underline;
  }
}

.footer__link__item {
  margin-bottom: 0.5rem;
}

.footer__heading__item {
  @include font-size(12);
  text-transform: uppercase;
  margin-top: 2rem;
  @include mq(64em) {
    margin-top: 0;
  }
}

.footer__link {
  @include font-size(16);
  font-weight: $bold;
}

.footer__social__link {
  display: inline-block;
  width: 1.25rem;
  vertical-align: sub;
  margin-right: 0.5rem;
}

.footer__main__grid__item {
  width: 100%;
  padding: 0 1rem;
  text-align: center;
  @include mq(30em) {
    text-align: left;
    float: left;
    width: 50%;
    &:nth-child(n) {
      clear: none;
    }
    &:nth-child(4n+1) {
      clear: both;
    }
  }
  @include mq(64em) {
    text-align: left;
    float: left;
    width: 25%;
    &:nth-child(n) {
      clear: none;
    }
    &:nth-child(4n+1) {
      clear: both;
    }
    &:nth-child(1) {
      width: 22%;
    }
  }
}

.footer__bottom {
  background: $blue-dark;
  padding: 1rem 0;
  @include mq(64em) {
    padding: 0;
    text-align: center;
  }
}

.footer__bottom__wrap {
  @extend .wrap;
  @include mq(64em) {
    @include clear;
    display: flex;

    align-items: center;

  }

}

.footer__bottom__link {
  padding: 0.5rem 1rem;
  @include font-size(12);
  text-transform: uppercase;
  font-weight: $bold;
  display: block;
  @include mq(64em) {
    padding: 1rem;
    display: inline-block;
  }
  &:hover {
    text-decoration: underline;
  }
}

.OU-edit {
  cursor: default;
}

.footer__bottom__text {
  padding: 0.5rem 1rem;
  @include font-size(12);
  text-transform: uppercase;
  font-weight: $bold;
  display: block;
  @include mq(64em) {
    padding: 1rem;
    display: inline-block;
  }
}
