.media {
  margin-bottom: 2rem;
}

.media__item {
  display: inline-block;
  position: relative;
  img {
    width: 100%;
  }
  @include mq(48em) {
    display: block;
  }
}

.media__link {
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: background $transition;
  }
  &:hover {
    &:before {
      background: $black-opacity;
    }
    .circle__icon {
      background: $purple;
    }
    .play__icon {
      color: $purple;
    }
  }
}

.media__caption {
  margin-top: 1rem;
}

.media__caption--large {
  @include font-size(22);
  font-weight: $light;
}


.media__icon {
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem;
}

.media__icon--alt {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  left: auto;
}

.media__gallery {
  visibility: hidden;
  display: none;
}

//

.media__home__slider {
  max-width: $maxScreen;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
  .slick-next, .slick-prev {
    @include mq(32em) {
      top: 30%;
      transform: translateY(-30%);
    }
  }
}

.media__home__slider__item {
  display: flex !important;
  @include mq(54em) {
    opacity: .3;
  }
}

.slick-center {
  opacity: 1 !important;
}


.media__home__slide {
  width: 50%;
  margin: 0 1.5rem;
  @include mq($min: 49em, $max: 54em) {
    opacity: .3;
  }
  @include mq(32em) {
    margin: 0 1rem;
  }
  // @include mq(48em) {
  //   margin: 0 0.35rem;
  // }
}
//

.media__feature {
  padding: 0 1rem;
  max-width: $maxScreen;
  margin: 0 auto;
  @include mq(48em) {
    padding: 1.2rem;
  }
  .grid__item {
    padding: 0;
    @include mq(48em) {
      padding: 0 1rem;
    }
  }
}
.media__feature__item {
  @include clear;
  position: relative;
  .grid__item:nth-of-type(1) & {
    margin-bottom: 2rem;
  }

  overflow: hidden;
  @include mq(64em) {
    .grid__item:nth-of-type(1) & {
      margin-bottom: 0;
    }
  }


  img {
    width: 100%;
  }

}

.media__feature__content {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  color: $white;
  text-align: center;
  padding: 3rem 0 2rem;
  height: 18rem;
  margin-bottom: -10rem;
  transition: height $transition;
  @include smooth-font;
  background: rgba($purple-dark, .85);
  border-radius: 100% 100% 0px 0px;
  width: 145%;
  margin-left: -22%;

  .media__mobile__animated & {
    height: 100%;
    width: 100%;
    border-radius: 0;
    margin-bottom: 0;
    padding: 3rem 6rem;
    margin-left: 0;
  }

  &:before {
    position: inherit;
    content: '';
    @include fill($svgstore--ArrowUp, $white);
    width: 1rem;
    height: 1rem;
    top: 1rem;
    .media__mobile__animated & {
      transform: rotate(180deg);
    }
    @include mq(48em) {
      background-image: none;
    }
  }
  @include mq(48em) {
    transition: margin-bottom 1s ease, width 1s ease, border-radius 2s ease;
    width: auto;
    margin-left: auto;
    padding: 2rem;
    margin-bottom: -230px;
    border-radius: 0;
    height: auto;
  }
}

.media__feature__content__wrapper {
  @include mq(64em) {
    position: relative;
  }
  .media__mobile__animated & {
    position: absolute;
    bottom: 2rem;
    left: 1rem;
    right: 1rem;
  }
}


// .media__feature__img {
//   @include mq($max:48em) {
//     &:before {
//       position: absolute;
//       content: '';
//       width: 100%;
//       height: 100%;
//       left: 0;
//       top: 0;
//       background-color: rgba($purple-dark, .85);
//     }
//   }
// }

.media__feature__content.media__animated {
  border-radius: 100% 100% 0px 0px;
  width: 140%;
  margin-left: -20%;
  margin-bottom: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include fluid-type(padding-left, $minScreen, $maxScreen, 100px, 200px);
  @include fluid-type(padding-right, $minScreen, $maxScreen, 100px, 200px);
  @include fluid-type(padding-top, $minScreen, $maxScreen, 50px, 50px);
  @include fluid-type(padding-bottom, $minScreen, $maxScreen, 100px, 100px);

  @include mq(48em) {
    @include fluid-type(padding-left, $minScreen, $maxScreen, 0px, 200px);
    @include fluid-type(padding-right, $minScreen, $maxScreen, 0px, 200px);
    @include fluid-type(padding-top, $minScreen, $maxScreen, 50px, 50px);
    padding-bottom: 2rem;
  }
  @include mq(64em) {
    @include fluid-type(padding-left, $minScreen, $maxScreen, 100px, 200px);
    @include fluid-type(padding-right, $minScreen, $maxScreen, 100px, 200px);
    @include fluid-type(padding-top, $minScreen, $maxScreen, 50px, 50px);
    padding-bottom: 2rem;
  }
  .wrap--relative {
    margin-bottom: -4rem;
  }
}

.media__feature__icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;
  @include mq($max:48em) {
    .media__mobile__animated & {
      .circle__icon {
        background-color: $white;
        color: $purple-dark;
      }
    }
  }
}


.media__animate {
  @include mq(48em) {
    visibility: hidden;
  }
}

.media__animated {
  @include mq(48em) {
    visibility: visible !important;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
}





.media__feature--name {
  @include font-size(22);
}

.media__feature--title {
  @include font-size(15);
  font-weight: $bold;
}

.media__feature--paragraph {
  @include font-size(22);
  font-weight: $bold;
  margin: 2rem auto;
  // max-width: 35.875rem;
  transition: none;
}




.media__slider {
  .slick-next,
  .slick-prev {
    @include mq($max: 32em) {
      top: 35%;
      transform: translateY(-35%);
    }

  }
}
