.pre-footer {
  padding: 3rem 0 2rem;
  @include mq(48em) {
    padding: 5rem 0 4rem;
  }
  @include mq(64em) {
    padding: 8rem 0 7rem;
  }

  ul li {
    width: 90%;
    margin-bottom: 1rem;
    @include mq(48.063em) {
      width: auto;
    }
  }
}

.pre-footer__header {
  text-align: center;
  margin-bottom: 3rem;
}

.pre-footer__header__logo {
  display: block;
  margin: 0 auto;
  max-width: 35rem;
  margin-bottom: 1rem;
  @include mq(48em) {
    width: 100%;
    margin-bottom: 0;
    margin-right: 0.8rem;
    display: inline-block;
    vertical-align: middle;
  }
}

// .pre-footer__header__text {
//   text-transform: uppercase;
//   line-height: 1.2;
//   display: block;
//   text-align: center;
//   border-top: 0.1rem solid $white;
//   padding-top: 1rem;
//   max-width: 25rem;
//   margin: 0 auto;
//   @include font-size(20);
//   @include mq(48em) {
//     padding-top: 0;
//     text-align: left;
//     border-top: 0;
//     border-left: 0.1rem solid $white;
//     display: inline-block;
//     vertical-align: middle;
//     padding-left: 1rem;
//   }
// }

