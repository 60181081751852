$tcon-size: 32px !default;
$tcon-menu-size: 30px;
$tcon-transition: .3s !default;
$tcon-jstransform: 'tcon-transform' !default;

$tcon-menu-radius: ($tcon-size / 14) !default;
$tcon-menu-color: $white !default;
$tcon-menu-arrowleft-transformation: (
  transform: scale3d(.8, .8, .8) rotate3d(0, 0, 1, 90deg)
  ) !default;
$tcon-menu-arrow360left-transformation: (
  transform: scale3d(.8, .8, .8) rotate3d(0, 0, 1, 360deg)
  ) !default;
.tcon {
  appearance: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $tcon-size;
  transition: $tcon-transition;
  user-select: none;
  width: $tcon-size;
  background: transparent;
  // outline: none;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  > * {
    display: block;
  }

  &:hover,
  &:focus {
    // outline: none; // see issue #36 https://github.com/grayghostvisuals/transformicons/issues/36
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

@mixin tcon-menu-lines {
  display: inline-block;
  height: ($tcon-size / 16);
  width: $tcon-size;
  border-radius: 0;
  transition: $tcon-transition;
  background: $tcon-menu-color;
}

.tcon-menu__lines {
  @include tcon-menu-lines;
  position: relative;
  width: $tcon-menu-size;


  &::before,
  &::after {
    @include tcon-menu-lines;
    height: ($tcon-size / 16);
    content: '';
    position: absolute;
    left: 0;
    transform-origin: ($tcon-size / 14) center;
    width: 100%;
  }

  &::before {
    top: ($tcon-size / 3.5);
  }

  &::after {
    top: -($tcon-size / 3.5);
  }

  .#{$tcon-jstransform} & {
    transform: scale3d(.6, .6, .6);
  }
}
.tcon-menu--xcross {
  width: auto;

  &.#{$tcon-jstransform} {
    .tcon-menu__lines {
      background: transparent;

      &::before,
      &::after {
        transform-origin: 50% 50%;
        top: 0;
        width: $tcon-size;
        height: ($tcon-size / 8);
      }

      &::before {
        transform: rotate3d(0, 0, 1, 45deg);
      }

      &::after {
        transform: rotate3d(0, 0, 1, -45deg);
      }
    }
  }
}
// see issue #7 https://github.com/grayghostvisuals/transformicons/issues/7#issuecomment-59813596
.tcon-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}
$tcon-search-size: ($tcon-size * 0.75) !default;
$tcon-search-radius: 0 !default;
$tcon-search-color: white !default;
$tcon-search-glass-color: $purple-dark !default;
$tcon-search-handle-width: ($tcon-size / 10) !default;
$tcon-search-glass-radius: ($tcon-size/ 2) !default;
$tcon-search-glass-border-width: ($tcon-size / 16) !default;
@mixin search-item {
  display: inline-block;
  height: $tcon-search-size;
  width: $tcon-search-size;
  border-radius: 0;
  transition: $tcon-transition;
  background: transparent;
}

.tcon-search__item {
  @include search-item;
  position: relative;
  transform: rotate(45deg);

  &::before,
  &::after {
    @include search-item;
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &::before {
    height: $tcon-search-handle-width;
    right: 0;
    background: $tcon-search-color;
    border-radius: $tcon-search-radius;
  }

  &::after {
    left: 0;
    background: $tcon-search-glass-color;
    .nav__search & {
      background: $purple-dark;
    }
    border-radius: 50%;
    width: $tcon-search-glass-radius;
    height: $tcon-search-glass-radius;
    border: $tcon-search-glass-border-width solid white;
    transform-origin: 50% 50%;
  }
}
.tcon-search--xcross {
  &.#{$tcon-jstransform} {
    transform: scale3d(.8, .8, .8);

    .tcon-search__item {
      &::before, &::after {
        border-radius: 0;
        background: $tcon-search-color;
      }

      &::after {
        height: $tcon-search-handle-width;
        width: 100%;
        top: 0;
        right: 0;
        border: 0;
        transform: rotate3d(0, 0, 1, 90deg);
      }
    }
  }
}

//

.circle__arrow {
  display: block;
  width: 2rem;
  height: 2rem;
  background: $yellow;
  padding: 0 0.5rem;
  border-radius: 50%;
  transition:
  background-color $transition,
  color $transition;
  &:hover,
  &:focus,
  &:active {
    background: $blue;
    color: $white;
  }
}

.square__arrow {
  display: block;
  width: 3rem;
  height: 3rem;
  background: $yellow;
  padding: 0.8rem;
  color: $black;
  transition:
  background-color $transition,
  color $transition;
  &:hover,
  &:focus,
  &:active {
    background: $blue;
    color: $white;
  }
}

.circle__icon {
  display: block;
  width: 1.75rem;
  height: 1.75rem;
  background: $purple-dark;
  border-radius: 50%;
  color: $white;
  transition:
  background-color $transition;
  padding: .45rem;
  &:hover,
  &:focus,
  &:active {
    background: $purple;
  }
}

.play__icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 3.5rem;
  color: $purple-dark;
}

.social__icon {
  display: inline-block;
  vertical-align: middle;
  width: 1.5rem;
  color: $gray;
  transition: color $transition;
}

.social__icon--alt {
  display: inline-block;
  vertical-align: middle;
  width: 1.8rem;
  color: $gray;
  transition: color $transition;
}

.social__icon__list {
  @include clean;
  list-style: none;
  li {
    display: inline-block;
    margin: 0 0.25rem 0;
  }
}

.facebook {
  &:hover {
    color: $facebook;
  }
}

.instagram {
  &:hover {
    color: $instagram;
  }
}

.twitter {
  &:hover {
    color: $twitter;
  }
}

.youtube {
  &:hover {
    color: $youtube;
  }
}

.linkedin {
  &:hover {
    color: $linkedin;
  }
}