// colors
$white: #ffffff;
$black: #010101;
$gray: #53565A;
$gray-light: #d8d6d6;
$gray-lighter: #EFEFEF;

$red: #DA291C;
$maroon: #651D32;
$purple: #9D56CC;
$purple-dark: #702F8A;
$purple-darker: #45105B;

$yellow: #E1D555;
$yellow-alt: #FFC72C;

$orange: #ED8B00;
$orange-alt: #FFCD86;

$green: #DDEFEC;
$green-dark: #A0D1CA;
$teal: #56919A;

$blue: #236192;
$blue-dark: #012169;
$blue-alt: #135888;

$gray-opacity: rgba($gray, 0.5);
$black-opacity: rgba(#000, 0.4);


// Social colors
$twitter: #00aced;
$facebook: #3b5998;
$linkedin: #007bb6;
$youtube: #bb0000;
$instagram: #bc2a8d;

// fonts
$sans-serif: 'Raleway', sans-serif;

$bold: 700;
$reg: 400;
$light: 300;

// transitions
$transition-speed: 300ms;
$transition-easing: ease;
$transition: $transition-speed $transition-easing;

$line-height-tall: 6.25rem;
$line-height-short: 3.1rem;

$header-transition-speed: 500ms;
$header-transition-ease: ease;
$header-transition: $header-transition-speed $header-transition-ease;


// animations
@keyframes fadeUp {
  0% {
    transform: translateY(70%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes topIn {
  0% {
    top: -100%;

  }
  100% {
    top: 0;

  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



// misc

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}


.appear-bottom {
  opacity: 0;
  transform: translateY(80px) translateZ(0);
  transition:
  transform 700ms cubic-bezier(.165,.84,.44,1),
  opacity 700ms cubic-bezier(.165,.84,.44,1);
}

.appear-bottom.is-active {
  opacity: 1;
  transform: translateZ(0);
}
