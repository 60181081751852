.hero {
  position: relative;
}

.hero__image {

  @include mq(32em) {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }

}

.hero__wrap {
  position: absolute;
  top: 0;
  @include mq(32em) {
    position: relative;
  }
  padding-top: 1rem;
  padding-bottom: 5rem;
  @extend .wrap;
  @include mq(32em) {
    padding-top: 5rem;
    min-height: 28.125rem;
  }
}

.hero__logo {
  width: 8rem;
  color: $white;
  display: inline-block;
  margin-right: 1.75rem;
  .internal & {
    display: none;
  }
  @include mq(32em) {
    width: 10rem;
  }
  @include mq(64em) {
    width: 5rem;
  }
  .home & {
    display: block;
    width: 8rem;
    margin-bottom: 3rem;
    @include mq(32em) {
      width: 10rem;
    }
  }
}

.hero__tagline {
  display: none;
  @include mq(64em) {
    .internal & {
      display: none;
    }
    position: relative;
    display: inline-block;
    background-color: rgba($purple-dark, .85);
    padding: 1rem 1.7rem;
    @include font-size(48);
    color: $white;
    text-decoration: none;
    .home & {
      @include font-size(80);
    }
  }

}