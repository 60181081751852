.grid {
  display: flex;
  flex-flow: row wrap;
  margin-right: -1rem;
  margin-left: -1rem;

}

.grid--center {
  justify-content: center;
}

.grid--wide {

  @include mq(64em) {
    margin-left: -3rem;
    margin-right: -3rem;
  }
}

.grid--narrow {
  @include mq(64em) {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }

}

.grid--slim {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}

.grid__item {
  // display: flex;
  // flex-flow: column wrap;
  padding: 1rem;
}

.grid__item--no-v-padding {
  padding: 0 1rem;
}

.grid__item--match {
  flex-flow: row wrap;
  > * {
    display: flex;
    flex-flow: column wrap;
  }
}

.grid__item--narrow {
  @include mq(64em) {
    padding: 0 0.5rem;
  }
}

.grid__item--slim {
  padding: 0 0.25rem;
}

.grid__item--wide {
    margin-left: auto;
    margin-right: auto;
    @include mq(48em) {
    padding: 0 0.5rem;
  }
  @include mq(64em) {
    padding: 0 3rem;
  }
}

.grid__item--2 {
  width: 100%;
  @include mq(48em) {
    float: left;
    width: 50%;
    &:nth-child(2n+1) {
      clear: both;
    }
  }
}


.grid__item--3 {
  width: 100%;
  @include mq(48em) {
    float: left;
    width: 33.333%;
    &:nth-child(3n+1) {
      clear: both;
    }
  }
}

.grid__item--3-2 {
  @include mq(64em) {
    float: left;
    width: 66.666%;
  }
}

.grid__item--4 {
  @include mq(48em) {
    float: left;
    width: 50%;
    &:nth-child(2n+1) {
      clear: both;
    }
  }
  @include mq(64em) {
    width: 25%;
    &:nth-child(n) {
      clear: none;
    }
    &:nth-child(4n+1) {
      clear: both;
    }
  }
}

.grid__item--5 {
  @include mq(32em) {
    width: 100%;
    float: left;
    &:nth-child(2n+1) {
      clear: both;
    }
  }
  @include mq(48em) {
    width: 100%;
    &:nth-child(n) {
      clear: none;
    }
    &:nth-child(3n+1) {
      clear: both;
    }
  }
  @include mq(64em) {
    width: 20%;
    &:nth-child(n) {
      clear: none;
    }
    &:nth-child(5n+1) {
      clear: both;
    }
  }
}
