.block {
  position: relative;
  overflow: hidden;
  .grid {
    align-items: center;
  }
}



.block__img {
  position: relative;
  min-height: 10rem;
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba($black, 0.2);
  }
  img {
    width: 100%;
  }
}

.block__content {
  position: absolute;
  width: 100%;
  bottom: 5rem;
  max-width: $maxScreen;
  @include mq(30em){
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  .block-mid & {
    bottom: 1rem;
    top: auto;
    transform: none;
    @include mq(48em) {
      bottom: 3rem;
    }
  }
}

.block__text {
  display: block;
  width: 100%;
  @include font-size(38);
  color: $white;
  text-shadow: 0 0 6px rgba(0,0,0,0.50);
}

.block__heading {
  font-weight: $bold;
  color: $white;
  @include fluid-type(font-size, $minScreen, $maxScreen, 54px, 84px);
  text-shadow: 0 0 10px rgba(0,0,0,0.50);
  line-height: 1;
  margin-bottom: 2rem;
  width: 95%;
  .block-mid & {
    @include fluid-type(font-size, $minScreen, $maxScreen, 32px, 60px);
  }
}
