.subnav__list--horizontal {
  @include clean;
  list-style: none;
  text-align: center;
}

.subnav__item--horizontal {
  display: inline-block;
  margin: 0 1rem;
  overflow: hidden;
  padding-bottom: 5px;
}

.subnav__link--horizontal {
  color: $purple-dark;
  @include font-size(17);
  font-weight: $bold;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  transition: color $transition;

  &:after {
    content: '';
    transition: all $transition;
    backface-visibility: hidden;
    position: absolute;
    bottom: -5px;
    height: 2px;
    width: 100%;
    background: $purple;
    left: 50%;
    transform: translateX(-50%) scaleX(0);

  }
  &:hover,
  &:focus {
    color: $purple;
    &:after {
      transform: translateX(-50%) scaleX(1);
    }
  }
}

.subnav__toggle {
  padding: 0.6rem 0;
  border-bottom: .3rem solid $purple-dark;
  width: 100%;
  text-align: left;
  display: flex;
  outline: 0;
  @include mq(64em) {
    cursor: default;

  }
}

.subnav__heading {
  @include font-size(15);
  text-transform: uppercase;
  width: calc(100% - 1rem);
  font-family: $sans-serif;
}

.subnav__dropdown {
  display: inline-block;
  width: 1rem;
  height: 0.5rem;
  margin-top: 0.35rem;
  transition: transform $transition;
  .subnav--active & {
    transform: rotate(-180deg);
  }
  @include mq(64em) {
    display: none;
  }
}

.subnav__list {
  @include sr-hide;
  @include mq(64em) {
    @include sr-show;
  }
  .subnav--active & {
    @include sr-show;
  }
}

.subnav__list,
.subnav__list--nested {
  @include clean;
  list-style: none;
}

.subnav__list--nested {
  margin-bottom: 1rem;
}

.subnav__item,
.subnav__item--nested {
  @include font-size(15);
  margin-bottom: 0;
}

.subnav__item {
  border-bottom: 0.1rem solid $green-dark;
}

.subnav__link,
.subnav__link--nested {
  text-decoration: none;
  color: $black;
  display: block;
}

.subnav__link {
  font-weight: $bold;
  padding: 0.8rem;
  &:hover,
  &:focus {
    background-color: $purple;
    color: $white;
  }
}


.subnav__link--nested {
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  &:hover,
  &:focus {
    color: $purple;
  }
}

.subnav__link--current {
  color: $purple;
}

