.nav__wrap {
  position: absolute;
  width: 100%;
  background-color: $purple-dark;
  .menu-active & {
    z-index: 1;
  }
}

.nav {
  position: relative;
  visibility: hidden;
  transform: translate(0, -100%);
  z-index: -1;
  overflow: hidden;
  max-height: 0;
  transition:
  transform $header-transition,
  visibility $header-transition,
  max-height $header-transition;
  .menu-active & {
    visibility: visible;
    transform: translate(0,0);
    max-height: 100vh;

  }
  @include mq(1025px) {
    visibility: visible;
    overflow: visible;
    transform: none;
    max-height: none;
    transition: none;
    z-index: 1;
  }
  .wrap {
    padding-left: 0;
    padding-right: 0;
    @include mq(1025px) {
      display: flex;
      align-items: center;
      padding-left: 1rem;
      padding-right: 1rem;
      .header--collapsed &,
      .internal & {
        max-width: 90rem;
        padding-right: 0;
        padding-left: 0;
      }
    }
    @include mq(72em) {
      .header--collapsed &,
      .internal & {
        padding-left: 1rem;
      }
    }
  }
}

.nav__list {
  padding-left: 0;
  list-style: none;
  margin: 0;
  opacity: 0;
  transition: opacity $transition 200ms;
  .menu-active & {
    opacity: 1;
  }
  @include mq(1025px) {
    opacity: 1;
    display: flex;
    li:hover {
      .no-touchevents & {
        .nav__list--sub {
          position: absolute;
          left: 0;
          visibility: visible;
          max-height: 600px;
        }
      }
      &:last-child {
        .nav__list--sub {
          left: auto;
          right: 0;
        }
      }
    }
  }
}

.nav__item {
  padding: 0;
  margin-bottom: 0;
  border-top: 1px solid $purple;
  @include mq(1025px) {
    display: table;
    position: relative;
    padding: 0;
    border-top: 0;
    border-left: 1px solid $purple;
    &:hover {
      .no-touchevents & {
        background-color: $purple;
        .nav__link {
          text-decoration: underline;
        }
        .nav__list--sub {
          position: absolute;
          left: 0;
          visibility: visible;
        }
        .nav__link__toggle {
          pointer-events: none;
          transform: rotate(180deg);
        }
      }
    }
  }
  &:last-child {
    @include mq(1025px) {
      border-right: 1px solid $purple;
    }
  }
}

.nav__link {
  position: relative;
  display: inline-block;
  width: calc(100% - 2.5rem);
  color: $white;
  font-family: $sans-serif;
  font-weight: bold;
  @include smooth-font;
  @include font-size(15);
  text-decoration: none;
  padding: 1rem 0 1rem 1rem;
  text-align: left;

  @include mq(1025px) {
    width: calc(100% - 2rem);
    white-space: normal;
    height: 76px;
    max-width: 220px;
  }
}

.nav__link__toggle {
  display: inline-block;
  vertical-align: middle;
  width: 1.75rem;
  color: $white;
  padding: 0.5rem;
  @include mq(1025px) {
    height: 76px;
    vertical-align: top;
  }
  .nav__item--active & {
    transform: rotate(180deg);
  }
}


.nav__list--sub {
  @include hide;
  visibility: hidden;
  padding: 1rem 0;
  margin: 0;
  @include mq(1025px) {
    background-color: $gray-light;
    width: 20rem;
    max-height: 0;
    overflow: hidden;
    transition: max-height 800ms;
  }
  .nav__item--active & {
    position: static;
    visibility: visible;
    @include mq(1025px) {
      position: absolute;
      left: 0;
      max-height: 600px;
    }
  }
}

.nav__item--sub {
  padding: 0.25rem 1rem;
  margin-bottom: 0;
}

.nav__link--sub {
  display: inline-block;
  font-family: $sans-serif;
  font-weight: bold;
  @include smooth-font;
  @include font-size(15);
  color: $white;
  padding: 0.25rem 1rem;
  text-decoration: none;
  transition:
  background $transition,
  color $transition;
  @include mq(1025px) {
    display: block;
    color: $black;
  }
  &:hover {
    background-color: $purple;
    color: $white;
  }
}

.nav__search {
  display: none;
  @include mq(1025px) {
    display: inline-block;
    vertical-align: middle;
    width: 76px;
    .tcon-search--xcross {
      width: 76px;
      height: 76px;
      margin: 0 auto;
    }
    .search-active & {
      background-color: $purple;
    }
  }
}

.nav__logo {
  display: none;
  color: $white;
  @include font-size(21);
  text-decoration: none;
  margin-left: 0;
  margin-right: auto;
  @include mq(80em) {
    @include font-size(36);
  }
  .header--collapsed &,
  .internal & {
    display: none;
    @include mq(1025px) {
      display: block;
      white-space: nowrap;
      margin-right: 2rem;
      margin-left: 2rem;
    }
    @include mq(80em) {
      margin-right: 4rem;
    }
  }
}