.button {
  @include font-size(17);
  font-weight: $bold;
  text-decoration: none;
  padding: 1rem 2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition:
  color $transition,
  background-color $transition;
  display: inline-block;
  // white-space: nowrap;
}

.button__link {
  @extend .button;
  color: $purple;
  padding: 0;
  position: relative;
  margin-bottom: 3px;
  white-space: inherit;
  &:after {
    content: '';
    transition: all $transition;
    backface-visibility: hidden;
    position: absolute;
    bottom: -3px;
    height: 2px;
    width: 100%;
    background: $purple;
    left: 50%;
    transform: translateX(-50%) scaleX(0);
  }
  &:hover {
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    transition: color $transition;
    &:after {
      transform: translateX(-50%) scaleX(1);
    }
  }
}

.button__solid {
  @extend .button;
  padding: 0.5rem 1rem;
  color: $black;
  background-color: $yellow;
  &:hover {
    color: $white;
    background-color: $blue;
  }
}

.button__ghost {
  @extend .button;
  padding: 0.5rem 1.5rem;
  color: $white;
  border: 1px solid $white;
  position: relative;
  white-space: normal;
  @include mq(48em) {
    border: 0;
    border-top: 1px solid $white;
    white-space: nowrap;
    &:hover {
      .button__border {
        span {
          &:first-child:before,
          &:last-child:before {
            animation: topIn 300ms forwards;
          }
          &:first-child:after {
            left: 0;
            opacity: 1;
          }
          &:last-child:after {
            right: 0;
            opacity: 1;
          }
        }
      }
    }
  }

}

.button__border {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  span {
    &:before,
    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 0;
      width: 0;
      background-color: $white;

    }
    &:first-child:before,
    &:last-child:before {
      width: 1px;
      height: 100%;
      top: -100%;
    }
    &:first-child:before{
      left: 0;
    }
    &:last-child:before {
      right: 0;
    }

    &:first-child:after {
      width: 50%;
      height: 1px;
      left: -100%;
      bottom: 0;
      opacity: 0;
      transition: left $transition, opacity $transition;
    }

    &:last-child:after {
      width: 50%;
      height: 1px;
      right: -100%;
      bottom: 0;
      opacity: 0;
      transition: right $transition, opacity $transition;

    }
  }
}

.button__overlay {
  @extend .button;
  position: relative;
  // z-index: 1;
  color: $white;
  padding: 1.6rem 2.8rem;
  box-shadow: inset 0 0 0 .4rem $yellow-alt;
  text-shadow: 0 0 4px rgba(0,0,0,0.80);
  transition: box-shadow $transition;
  &:hover {
    box-shadow: inset 0 0 0 .8rem $yellow-alt;
  }
}

.button__overlay--alt {
  @extend .button;
  padding: 0.5rem 1.5rem;
  color: $white;
  box-shadow: inset 0 0 0 .1rem $white;
  transition: box-shadow $transition;
  display: inline;

  &:hover,
  &:active {
    box-shadow: inset 0 0 0 .2rem $white;

  }
}

.button__ghost__group {
  @extend .ul--plain;
  @include spacing(1);
  li {
    margin: 1rem;
    @include mq(48em) {
      margin: 0 0.5rem 0.25rem;
      display: inline-block;

    }
    .button__ghost {
      display: block;
    }
  }
}

.button__group {
  @extend .ul--plain;
  @include spacing(2);
  @include mq($max: 48em) {
    text-align: center;
  }
  li {
    display: inline-block;
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
  }
}

.button__group--alt {
  @extend .ul--plain;
  @include spacing(2);
  li {
    display: inline-block;
    margin: 0.5rem;
  }
}

.button__list {
  @extend .ul--plain;
  li {
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  li  + li  {
    &:before {
      content: '|';
      padding-right: 0.5rem;
    }
  }

}

.button-tag {
  display: inline-block;
  border-radius: 1rem;
  border: 0.1rem solid $gray;
  padding: 0.35rem 0.5rem;
  line-height: 1;
  text-transform: uppercase;
  @include font-size(12);
  font-weight: $bold;
  color: $gray;
  margin-bottom: 1.5rem;
}
