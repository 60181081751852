.pagination {
  @include clean;
  display: inline-block;
  li {
    float: left;
    display: block;
    margin: 0;
    @include mq(30em){
      margin: 0 0.1rem 0;
    }
  }
}

.pagination__link {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  background-color: $gray-lighter;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  @include font-size(15);
  color: $black;
  transition:
  background-color $transition,
  color $transition;
  text-decoration: none;
  &:hover,
  &:focus,
  &.pagination__link--active {
    background-color: $blue;
    color: $white;
  }
}

.pagination__icon {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.6rem;
}

.pagination__icon--prev {
  @extend .pagination__icon;
  @include mq(30em){
    margin-right: 0.8rem;
  }
}

.pagination__icon--next {
  @extend .pagination__icon;
  @include mq(30em){
    margin-left: 0.8rem;
  }
}

