.player {
  position: relative;
  padding-top: 5.5rem;
  margin-bottom: 2rem;
  color: $black;
  // .iOS & {
  //   padding-top: 6.5rem;
  // }
}

.btn__play {
  // width: 5.5rem;
  display: block;
  float: left;
  position: relative;
  cursor: pointer;
  color: $purple-dark;
  padding: .25rem;
  @include mq(64em) {
    padding: 0 1.25rem;
    .iOS & {
      padding: 0;
    }
  }
}

.btn__play--white {
  color: $white;
}

.btn__play__arrow,
.btn__play__pause {
  display: block;
  width: 3rem;
  color: $purple-dark;
  border: 0;
  position: relative;
  .iOS & {
    width: 60px;
  }
  &:hover,
  &:focus,
  &:active {
    color: $purple;
  }
}


.player__footer {
  background: $gray-lighter;
  position: absolute;
  bottom: 0;
  width: 100%;
  @include font-size(15);
  font-weight: $bold;
  display: flex;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0.5rem;
  @include mq(30em) {
    padding: 1.5rem 0 1.5rem 1rem;
  }
}

.player__controls {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  padding-right: 1rem;
}

.player__timeline, .player__volume__bar {
  height: 0.5rem;
  position: relative;
  background: $white;
  display: inline-block;
  cursor: pointer;
}

.player__volume__bar {
  background: $gray-light;
}

.player__timeline {
  margin-left: 10px;
  margin-right: 15px;
  border-radius: .3rem;
  width: 70%;
  @include mq(32em) {
    width: 50%;
  }
  .iOS & {
    @include mq(32em) {
      width: 100%;
    }
    @include mq(48em) {
      width: 65%;
    }
  }
}

.player__timeline__progress {
  border-radius: .3rem;
}

.player__timeline__time--end,
.player__timeline__time--now {
  display: none;
  @include mq(48em) {
    display: inline-block;
  }
}

.btn__volume {
  background-color: transparent;
  border: 0;
}

.player__volume {
  @include mq(48em) {
    margin-left: 1rem;
  }
  .iOS & {
    display: none;
  }
}

.player__volume__progress, .player__timeline__progress {
  background-color: $purple-dark;
  height: 100%;
  display: block;
}

.player__volume__bar {
  display: none;
  @include mq(30em) {
    display: inline-block;
    width: 80px;
  }
}


.btn__volume__icon,
.btn__volume__up {
  width: 0.5rem;
  display: inline-block;
  vertical-align: middle;
}

.btn__volume__up {
  color: $gray-lighter;
  .btn__volume--up & {
    color: $black;
  }
}
