header {
  position: relative;
  z-index: 20;
}

.header {
  position: absolute;
  background-color: $purple-dark;
  width: 100%;
}

.header--collapsed {
  position: fixed;
  top: 0;
}

.header__small {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: $purple-dark;
  position: relative;
  z-index: 1;
  @include mq(1025px) {
    display: none;
  }
}

.header__menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 5rem;
}

.header__logo {
  @include font-size(36);
  color: $white;
  text-decoration: none;
}

