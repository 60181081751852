.accordion {
  @include clear;
  margin-bottom: 2rem;
}

.accordion__item {
  margin-bottom: 0.125rem;
  border-bottom: 0.1rem solid $purple-dark;
}



.accordion__item--active {
  border-bottom: 0;
  .accordion__interior {
    border-bottom: 0.1rem solid $purple-dark;
    border-top: none;
  }
}

.accordion__toggle {
  display: block;
  padding: 1rem;
  @include font-size(20);
  font-weight: $bold;
  color: $purple-dark;
  text-decoration: none;

}

.accordion__icon {
  width: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  display: inline-block;
  float: left;
}

.accordion__text {
  display: block;
  overflow: hidden;
}

.accordion__icon__open {
  display: block;
  .accordion__item--active & {
    transform: rotate(90deg);
  }
}

.accordion__content {
  @include hide;
  .accordion__item--active & {
    position: static;
  }
}

.accordion__interior {
  padding: 1.5rem 0 1.5rem 2.25rem;
  border-top: 0.1rem solid $purple-dark;
}
