.tabs {
  margin-bottom: 2rem;
}

.tabs__nav {
  @include clear;
  position: relative;
  display: none;
  margin-bottom: -0.19rem;
  list-style: none;
  @include mq(48em) {
    display: table;
  }
}

.tabs__nav__item {
  display: table-cell;
  vertical-align: bottom;
  margin-bottom: 0;
  padding: 0 0.1rem;
}

.tabs__nav__link {
  display: block;
  padding: 0.5rem 1rem;
  border-bottom: 0.2rem solid $purple-darker;

  color: $white;
  text-decoration: none;
  font-weight: $bold;
  background: $purple-dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include font-size(17);
  .tabs__nav__item--active & {
    border-color: $purple-darker;
    background: $purple-darker;
  }
}

.tabs__main {
  @include mq(48em) {
    border-top: 0.2rem solid $purple-darker;
  }
}

.tabs__main__item {
  @extend .accordion__item;
  @include mq(48em) {
    border-bottom: 0;
    margin: 0;
  }
}

.tabs__main__toggle {
  @extend .accordion__toggle;
  @include mq(48em) {
    display: none;
  }
}

.tabs__main__icon {
  @extend .accordion__icon;
}

.tabs__main__icon__open {
  display: block;
  .tabs__main__item--active--accordion & {
    transform: rotate(90deg);
  }
}


.tabs__main__content {
  @include sr-hide;
  top: 0;
  .tabs__main__item--active--accordion & {
    visibility: visible;
    position: static;
    @include mq(48em) {
      position: absolute;
    }
  }
  .tabs__main__item--active & {
    visibility: visible;
    @include mq(48em) {
      position: static;
    }
  }
}

.tabs__main__interior {
  margin: 0;
  padding: 2.5rem 0;
  border: 0;
  border-top: 0;
  @include mq(48em) {
    padding: 3rem 0;
  }
}
