.back-to-top {
  position: fixed;
  bottom: 5%;
  right: 5%;
  display: none;
  z-index: 11;
  transition: opacity 0.3s linear;
}

.back-to-top.disabled {
  display: none !important;
}

.back-to-top button {
  outline: 0;
}

.back-to-top.active {
  display: block;
}
