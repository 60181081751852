blockquote {
  position: relative;
  margin: 0;
  @include clear;
  @include spacing(3);
  &:before,
  &:after {
    position: absolute;
    content: '';
  }

  &:before {
    display: block;
    @include fill($svgstore--quote, $purple-dark);
    width: 1.5rem;
    height: 1.5rem;
    top: -1.5rem;
    left: 0;
    background-size: auto 80%;
    .blockquote--imgQuote & {
      @include fill($svgstore--quote, $white);
      top: -2.2rem;
    }
  }
  &:after {
    display: block;
    @include fill($svgstore--quote, $purple-dark);
    width: 1.5rem;
    height: 1.5rem;
    bottom: -1.5rem;
    left: 0;
    background-size: auto 80%;
    transform: rotate(180deg);
    .blockquote--imgQuote & {
      @include fill($svgstore--quote, $white);
      bottom: -2rem;
    }
  }
  q {
    @include font-size(24);
    font-weight: $light;
    line-height: 1.416;
    .blockquote--imgQuote & {
      @include fluid-type(font-size, $minScreen, $maxScreen, 28px, 42px);
      color: $green-dark;
      line-height: 1.14;
      margin-bottom: 1rem;
      display: block;
    }
    &:before,
    &:after {
      content: '';
    }

  }
  cite {
    display: block;
    @include font-size(15);
    font-weight: $bold;
    font-style: normal;
    padding: 0.5rem 0;
  }
}

//

.blockquote--imgQuote {
  @include clear;

  @include mq(64em) {
    display: flex;
  }
  p {
    color: $white;
  }
  cite {
    color: $white;
    font-style: normal;
    font-weight: $reg;
  }
}

.cite--name {
  text-transform: uppercase;
  font-weight: $bold;
  color: $yellow;
}

.blockquote__wrap {
  background-color: $purple-dark;
  overflow: hidden;
  padding: 2rem 1rem;
  display: table;
  @include mq(48em) {
    padding: 1rem 2rem;
  }
  @include mq(64em) {
    padding: 1rem 4rem;
  }
}

.blockquote__content {
  display: table-cell;
  vertical-align: middle;
  .button__ghost {
    margin-top: 2rem;
  }
}

.blockquote__img,
.blockquote__wrap {
  width: 100%;
  @include mq(64em) {
    width: 50%;
  }

}

.blockquote__img {
  img {
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}
