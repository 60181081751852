.form {
  padding: 2rem 0;
  margin-bottom: 2rem;
  @include clear;
  @include mq($max: 48em) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  * + & {
    margin-top: 2rem;
  }

}

.form__label {
  @extend .h6;
  display: block;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.form__label--required {
  color: $red;
}

.form__input,
.form__textarea {
  background-color: $white;
  border: .3rem solid $gray;
  width: 100%;
  margin-bottom: 2rem;
  line-height: $baseline;
  padding: 0.25rem 3rem 0.25rem 0.5rem;
  &:focus {
    border: 0.3rem solid $blue;
    outline: 0;
  }
}

.form__input {
  height: 2.8rem;
}

.form__input--success {
  border: .3rem solid #388407;
}

.form__input--error {
  border: .3rem solid $red;
}

.form__icon__placement {
  position: relative;
}


[class^="form__input__icon"] {
  display: block;
  width: 1.5rem;
  position: absolute;
  right: 0.75rem;
  top: 0.65rem;
}

.form__input__icon--error {
  color: $red;
}


.form__select {
  overflow: hidden;
  display: block;
  position: relative;
  margin-bottom: 2rem;
  background-color: $white;
  select {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0;
    z-index: 1;
    padding-left: 1rem;
    color: $gray;
    @include font-size(19);
    border: .3rem solid $gray;
    border-radius: 0;
    &:focus {
      border: .3rem solid $blue;
      outline: 0;
    }
  }
}

.form__select__text {
  width: 100%;
  height: 3.5rem;
  padding: 0 1rem;
  background-color: $white;
  margin: 0;
  float: left;
  width: calc(100% - 3.5rem);
  line-height: 3.25rem;
  padding: 0 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: transparent;
}

.form__select__arrow {
  position: absolute;
  right: 0;
  top: 0;
  width: 3.5rem;
  height: 3.5rem;
  padding: 1.5rem 1rem 1rem 1rem;
  background: $white;
  color: $gray;

  display: inline-block;
  select:focus ~ & {
    transform: rotate(180deg);

  }
}

.form__checkbox {
  display: block;
  position: relative;
  margin: 0.5rem 0;
  padding-left: 2.5rem;
  cursor: pointer;

  &:before {
    position: absolute;
    content: '';
    @include fill($svgstore--check_box_outline, $gray);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: auto 90%;
  }
}

.form__checkbox[aria-checked='true'] {
  &:before {
    @include fill($svgstore--check_box, $blue);
  }
}

.form__radio {
  display: block;
  position: relative;
  margin: 0.5rem 0;
  padding-left: 2.5rem;
  cursor: pointer;

  &:before {
    position: absolute;
    content: '';
    @include fill($svgstore--radial_button_unchecked, $gray);
    background-repeat: no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    top: 0;
    left: 0;
    background-size: auto 100%;
  }
}

.form__radio[aria-checked='true'] {
  &:before {
    @include fill($svgstore--radial_button_checked, $blue);
  }
}

.form_pages {
  select {
    -moz-appearance: menulist;    /* Firefox 40+ */
    -webkit-appearance: menulist; /* Chrome 45+ */
  }

  button,
  input,
  select,
  textarea {
    background-color: initial;
    border-style: solid;
    color: black;
    font-size: 1em;
    margin: 0;
    border: 1px solid black;
  }

  input,
  textarea {
    background-color: $white;
    border: .3rem solid $gray;
    width: 100%;
    margin-bottom: 2rem;
    line-height: $baseline;
    padding: 0.25rem 3rem 0.25rem 0.5rem;
    &:focus {
      border: 0.3rem solid $blue;
      outline: 0;
    }

    select {
      height: 3.5rem;
      border: .3rem solid $gray;
    }
  }
  .form_responses select {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding-left: 1rem;
    color: #53565a;
    font-size: 1.1875rem;
    border: .3rem solid #53565a;
    border-radius: 0;
  }
  select[multiple] {
    -webkit-appearance: none;
  }
  input[type=checkbox],input[type=radio] {
    width: inherit;
    margin: inherit;
  }
}

.form_action button.default {
  @extend .button;
  padding: 0.5rem 1rem;
  color: $black;
  background-color: $yellow;
  &:hover {
    color: $white;
    background-color: $blue;
  }
}

.form__inline__group {
  display: inline-block;
}



