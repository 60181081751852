.faculty {
  @include clear;
  @include spacing(3);
}

.faculty--alt {
  @include clear;
  @include spacing(4);
}

.faculty--slider-alt {
  @include mq(580px) {
    display: table;
  }
}

.faculty__img {
  margin-bottom: 1rem;
  .faculty__slider & {
    float: none;
    @include mq(32em) {
      float: left;
      margin-right: 1rem;
    }
  }
  @include mq(32em) {
    float: left;
    margin-right: 1rem;
  }
  .faculty--slider-alt & {
    margin-right: 0;
    margin-bottom: 0;
    float: none;
    @include mq(580px) {
      display: table-cell;
      img {

      }
    }
  }
}

.faculty__img--alt {
  margin-bottom: 1rem;
  @include mq(48em) {
    float: right;
  }
}

.faculty__image {
  .faculty--slider-alt & {
    @include mq(580px) {
      object-fit: cover;
      font-family: 'object-fit: cover;';
      @include mq(48em) {
        max-width: 40rem;
      }
    }
  }
}

.faculty__content {
  overflow: hidden;
  margin-right: 1rem;
  .faculty--slider-alt & {
    background: $gray-lighter;
    padding: 1rem;
    @include mq(64em) {
      padding: 0 4rem;
    }

  }
}

.faculty__content--alt {
  overflow: hidden;
  @include mq(48em) {
    padding-right: 2rem;
  }
}

.faculty__content--name {
  @extend .h3;
  margin-top: 0;
  .faculty__slide & {
    @extend .h5;
    margin-top: 0;
  }
}

.faculty__content--title {
  @extend .p--small;
  font-weight: $bold;
  margin-bottom: 0.8rem;
  .faculty--slider-alt & {
    margin-bottom: 2rem;
  }
}

.faculty__content--list {
  padding-left: 0;
  margin: 1.5rem 0;
  list-style: none;
  li {
    margin-bottom: 0;
  }

}

.faculty__slider,
.faculty__slider--alt {
  @extend .wrap;
}

.faculty__content--inline {
  display: table-cell;
  vertical-align: middle;
}


.faculty__slider-alt__paragraph {
  font-weight: $light;
  @include fluid-type(font-size, $minScreen, $maxScreen, 18px, 24px);
  line-height: 1.41;
}

.faculty--arrow .slick-arrow {
  @include mq($max: 32em) {
    top: 30%;
  }
}


.faculty__grid__item--2 {
  @include mq(64em) {
    float: left;
    width: 50%;
    &:nth-child(2n+1) {
      clear: both;
    }
  }
}

.faculty__listing {
  @include spacing(2);
  @include clear;
}

.faculty__listing__image {
  margin-right: 2rem;
  margin-bottom: 1rem;
  @include mq(48em) {
    float: left;
  }
}

.faculty__listing__image--swap {
  margin-bottom: 1rem;
  @include mq(48em) {
    margin-left: 2rem;
    float: right;
  }
}

.faculty__listing__header {
  overflow: hidden;
  > .image__heading .image__heading__text {
    color: $black;
  }

  h5 {
    margin-top: 0;
    @include font-size(18);
    margin-bottom: 0.5rem;
  }
  h5 + h5 {
    margin-top: -0.5rem;
  }
  a {
    display: inline-block;
    &:hover {
      text-decoration: none;

      .image__heading__text {
        -webkit-backface-visibility:hidden;
        backface-visibility:hidden;
        transition: color $transition;
        text-decoration: none;
        &:after {
          transform: translateX(-50%) scaleX(1);
        }
      }
    }
  }
}

.faculty__listing__contact {
  margin: 1rem 0;
}
.faculty__listing__title {
  margin-top: -0.5rem;
  @include font-size(18);
}

.faculty__page__link {

}

.form__input.faculty__search {
  width: 100%;
  margin-bottom: 1rem;
  @include mq(32em) {
    width: 50%;
    float: left;
    margin-bottom: 2rem;
  }
}

.faculty__submit {
  width: auto;
  @include mq(32em) {
    float: left;
    clear: none;
    padding-top: .75rem;
    padding-bottom: .75rem;
    margin-left: 1rem;
    width: auto;
  }
  
}
