.sidebar__panel {
  @include spacing(3);
  @include mq(48em) {
    @include spacing(2);
  }
  .h5 {
    margin-top: 1rem;
  }
}

.sticky {
  position: fixed;
}
